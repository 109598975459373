import * as constants from "./actionTypes";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleAPIError } from '../utility/actionHelper'
import { serialize, deserialize } from '../utility/serialization'

import Axios from '../axios'

export function successRequestingEvents(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_EVENTS,
      events: response.data.events,
      pages: response.data.meta.pagination
        ? response.data.meta.pagination.total_pages
        : null,
    }
  } else {
    console.log("ERROR ON GETTING EVENTS");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingEvent(response) {
  if (response.status === 200) {
    return { type: constants.GET_EVENT, event: response.data.event };
  } else {
    console.log("ERROR ON GETTING EVENT");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingCreateEvent(response) {
  if (response.status === 201) {
    return {
      type: constants.CREATE_EVENT,
      event: response.data.event
    };
  } else {
    console.log("ERROR ON CREATING EVENT");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingUpdateEvent(response) {
  if (response.status === 201) {
    return {
      type: constants.UPDATE_EVENT,
      event: response.data.event
    };
  } else {
    console.log("ERROR ON UPDATING EVENT");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingDeleteEvent(response) {
  if (response.status === 201) {
    return {
      type: constants.DELETE_EVENT,
      event: response.data.event,
    };
  } else {
    console.log("ERROR ON UPDATING EVENT");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// THUNKS HERE
export function getEvents(user_id, filters = {}, page = 1, perPage = 50) {
  return function(dispatch) {
    dispatch(showLoading())
    return requestEvents(user_id, filters, page, perPage)
      .then(response => dispatch(successRequestingEvents(response)))
      .then(_response => dispatch(hideLoading()))
  }
}

export function getEvent(event_id) {
  return function(dispatch) {
    dispatch(showLoading());
    return requestEvent(event_id)
      .then(response => dispatch(successRequestingEvent(response)))
      .then(response => dispatch(hideLoading()));
  };
}

export const getEventsByMonth = createAsyncThunk(
  'events/getEventsByMonth',
  async ({ selectedMonthDate }, { rejectWithValue }) => {
    try {
      const { data } = await requestGetEventsByMonth(selectedMonthDate)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

// Will replace the current getEvent function after removing the old version of 9HE
export const getEventDetail = createAsyncThunk(
  'nineHoleEvent/getEvent',
  async (eventId, { rejectWithValue }) => {
    try {
      const { data } = await requestEvent(eventId)
      return data
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export function createEvent(event) {
  return function(dispatch) {
    return requestCreateEvent(event).then(response =>
      dispatch(successRequestingCreateEvent(response))
    );
  };
}

export function updateEvent(event) {
  return function(dispatch) {
    dispatch(showLoading());
    return requestUpdateEvent(event).then(response => {
      dispatch(successRequestingCreateEvent(response));
      dispatch(hideLoading());
    });
  };
}

export function deleteEvent(event) {
  return function(dispatch) {
    dispatch(showLoading());
    return requestDeleteEvent(event).then(response => {
      dispatch(successRequestingDeleteEvent(response));
      dispatch(hideLoading());
    });
  };
}

export const deleteEnrollment = createAsyncThunk(
  'nineHoleEvent/deleteEnrollment',
  async ({ eventId, userId }, { rejectWithValue }) => {
    try {
      const { data } = await requestDeleteEnrollment(eventId, userId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const updateEnrollments = createAsyncThunk(
  'nineHoleEvent/updateEnrollments',
  async ({ event }, { rejectWithValue }) => {
    try {
      const { data } = await requestUpdateEnrollments(event)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

// API CALLS
function requestEvents(user_id, { search }, page, perPage) {
  const requestUrl = '/api/v1/events'

  const params = {
    user_id,
    page: page,
    per_page: perPage,
  }

  if (search) {
    params.search = search
  }

  return Axios.get(requestUrl, { params })
}

const requestGetEventsByMonth = (monthDate) => {
  const requestUrl = '/api/v1/events/dashboard_calendar'

  return Axios.get(requestUrl, { params: serialize({ monthDate: monthDate.toDateString() }) })
}

function requestEvent(event_id) {
  let requestUrl = "/api/v1/events/" + event_id;
  return Axios.get(requestUrl);
}

function requestCreateEvent(event) {
  let requestUrl = "/api/v1/events/";

  var data = new FormData();

  if (event.uploadPhotoFile) {
    data.append("event_photo", event.uploadPhotoFile);
  }

  if (event.editedStudents?.length > 0) {
    data.append('activities', JSON.stringify(event.editedStudents))
  } else if (event.students?.length > 0) {
    data.append('activities', JSON.stringify(event.students))
  }

  data.append('name', event.name)
  data.append('is_email', event.isEmail || event.is_email)
  data.append('description', event.description)
  data.append('location', event.location)
  data.append('creator_id', event.creator_id)
  data.append('play_type_id', event.selectedEvent.id || event.play_type_id)
  data.append('event_date', event.event_date)

  return Axios.post(requestUrl, data);
}

function requestUpdateEvent(event) {
  let requestUrl = "/api/v1/events/" + event.id;

  var data = new FormData();

  if (event.uploadPhotoFile) {
    data.append('event_photo', event.uploadPhotoFile)
  } else if (!event.uploadPhotoSrc) { // the difference between a not updated photo and a removed photo
    data.append('event_photo', 'removed')
  }

  if (event.editedStudents?.length > 0) {
    data.append('activities', JSON.stringify(event.editedStudents))
  } else if (event.students?.length > 0) {
    data.append('activities', JSON.stringify(event.students))
  }

  data.append("name", event.name);
  data.append("description", event.description);
  data.append("location", event.location);
  data.append("event_date", event.event_date);

  return Axios.put(requestUrl, data);
}

function requestDeleteEvent(event_id) {
  let requestUrl = "/api/v1/events/" + event_id;
  return Axios.delete(requestUrl);
}

function requestDeleteEnrollment(eventId, userId) {
  const requestUrl = '/api/v1/events/' + eventId + '/enrollments/' + userId
  return Axios.delete(requestUrl)
}

function requestUpdateEnrollments(event) {
  const requestUrl = '/api/v1/events/' + event.id + '/enrollments/'
  const data = serialize({ students: event.students })

  return Axios.put(requestUrl, data)
}
