/* eslint-disable react/no-deprecated */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Modal from 'react-modal'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'

import * as commonActions from '../../../actions/commonActions'
import * as userActions from '../../../actions/userActions'
import * as resourceActions from '../../../actions/resourceActions'
import * as courseActions from '../../../actions/courseActions'
import * as objectiveActions from '../../../actions/objectiveActions'
import * as activityActions from '../../../actions/activityActions'
import * as coachActions from '../../../actions/coachActions'
import * as communitiesActions from '../../../actions/communitiesActions'
import * as leaderboardActions from '../../../actions/leaderboardActions'
import * as rankActions from '../../../actions/rankActions'
import * as lessonPlanActions from '../../../actions/lessonPlanActions'
import * as scheduleActions from '../../../actions/scheduleActions'

import Loader from '../../common/Op36Loader'
import LessonReviewPublish from '../../lessonPlans/builder/builderViews/LessonReviewPublish'
import DrillDetailModal from '../../drills/modals/DrillDetailModal'
import CurriculumDetailModal from '../../lessonPlans/modals/CurriculumDetailModal'
import { withRouting } from '../../common/hooks'
import GolfersTabContainer from './GolfersTab/GolfersTabContainer'
import UnavailableClassesPage from '../UnavailableClassesPage'
import { checkLicensePermission } from '../../../utility/communityLicense'
import { Tab, Tabs, TabList, TabPanel } from '../../common/Tabs'
import ValidationModal from '../../common/ValidationModal'
import ConfirmationModal from '../../common/ConfirmationModal'
import ResourceModal from '../../resources/ResourceModal'
import LiveModal from '../ProgramBuilder/Modals/LiveModal'
import AddStudents from './AddStudents'
import ClassDetailHeader from './ClassDetailHeader'
import IssueTrophy from './IssueTrophy'
import LeaderboardPage from '../../leaderboard/LeaderboardPage'
import ActivityFeedPage from '../../activity/ActivityFeedPage'
import IssueBadges from './IssueBadges'
import ClassScheduleView from '../ScheduleBuilder/ClassScheduleView'

import '../../../assets/styles/components/classes/parentClasses.scss'
import '../../../assets/styles/components/classes/programs/programSessionDetailPage.scss'
import '../../../assets/styles/components/classes/ClassesPage.scss'
import '../../../assets/styles/components/classes/classDetailPage.scss'
import '../../../assets/styles/components/leaderboard/LeaderboardList.scss'
import lp_icon from '../../../assets/images/common/icons/lesson_plans/lesson-plan-icon.png'
import { programDeleted } from '../../../reducers/groupProgramsReducer'

require('react-datepicker/dist/react-datepicker.css')

class ClassDetailPage extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      course: {},
      selectedSubject: {},
      selectedDrill: {},
      selectedDrillId: -1,
      activeMembers: [],
      activeTab: 0,
      showApproveModal: false,
      showEditClassModal: false,
      showAddStudentModal: false,
      showIssueBadgesModal: false,
      isRankCompleted: false,
      leveledUpUser: false,
      isLoading: false,
      currentEnrollment: {},
      currentUserCurriculum: {},
      issueTrophyFormUserId: -1,
      issueTrophyFormUserIds: [],
      issueTrophyFormObjectiveId: -1,
      rankId: -1,
      objectiveId: -1,
      ranks: [],
      objectives: [],
      incompleteObjectives: [],
      alertTitle: 'Error',
      alertMessage: 'Something went wrong, please try again later.',
      alertType: 'danger',
      _isMounted: false, // https://facebook.github.io/react/blog/2015/12/16/ismounted-antipattern.html
      course_id: null,
      editCourseName: '',
      editCourseDescription: '',
      editCourseMeetingTimes: '',
      editCourseStartDate: '',
      editCourseEndDate: '',
      editCourseGolferLimit: 1,
      editCourseVisibility: '',
      editCourseIsDraft: false,
      showResourceModal: false,
      resource_id: -1,
      title: '',
      description: '',
      button_url: '',
      type: '',
      uploadPhotoFileSrc: '',
      uploadPhotoName: '',
      uploadPhotoFile: '',
      didUploadPhoto: false,
      isUpdate: false,
      studentLoader: false,
      showValidationModal: false,
      showCompleteModal: false,
      showCompleteClassModal: false,
      validationSuccess: true,
      validationMessage: '',
      addStudentLoader: false,
      courseLoader: false,
      scheduleLoader: false,
      removeLoader: false,
      removeEnrollmentId: null,
      userLoader: false,
      confirmationMessage: '',
      studentsLoader: false,
      courseStudents: [],
      errors: {},
      editStartStep: '1',
      showLiveModal: false,
    }

    this.setCurrentCourse = this.setCurrentCourse.bind(this)
    this.openApproveModal = this.openApproveModal.bind(this)
    this.closeApproveModal = this.closeApproveModal.bind(this)
    this.openEditClassModal = this.openEditClassModal.bind(this)
    this.deleteClass = this.deleteClass.bind(this)
    this.updateActiveMembers = this.updateActiveMembers.bind(this)
    this.editCourse = this.editCourse.bind(this)

    // ##
    this.toggleAddStudentModal = this.toggleAddStudentModal.bind(this)
    this.toggleIssueBadgesModal = this.toggleIssueBadgesModal.bind(this)
    this.closeEditClassModal = this.closeEditClassModal.bind(this)
    this.submitIssueTrophyForm = this.submitIssueTrophyForm.bind(this)
    this.handleRankObjectiveSelectChanged =
      this.handleRankObjectiveSelectChanged.bind(this)
    this.removeStudent = this.removeStudent.bind(this)
    this.progressRankForUser = this.progressRankForUser.bind(this)
    this.handleSubmitUserJoinCourse =
      this.handleSubmitUserJoinCourse.bind(this)
    this.approveEnrollment = this.approveEnrollment.bind(this)
    this.handleRankSelectChange = this.handleRankSelectChange.bind(this)
    this.handleObjectiveSelectChange =
      this.handleObjectiveSelectChange.bind(this)
    this.onSubmitBadges = this.onSubmitBadges.bind(this)
    this.getTabs = this.getTabs.bind(this)
    this.setEditStartStep = this.setEditStartStep.bind(this)
    this.setErrors = this.setErrors.bind(this)

    this.licenseType = this.props.current_user?.communities[0]?.license_type
  }

  setEditStartStep(step) {
    this.setState({ editStartStep: step })
  }

  setErrors(errors) {
    this.setState({ errors: errors })
  }

  defaultTrophyFormState() {
    this.setState({
      issueTrophyFormUserId: -1,
      issueTrophyFormObjectiveId: -1,
      showApproveModal: false,
      currentEnrollment: {},
      gotObjectivesForUser: -1,
    })
  }

  defaultIssueBadgesState() {
    this.setState({
      rankId: -1,
      objectiveId: -1,
      issueTrophyFormUserId: -1,
      issueTrophyFormObjectiveId: -1,
      showIssueBadgesModal: false,
      currentEnrollment: {},
      gotObjectivesForUser: -1,
    })
  }

  defaultUpdateCourseState() {
    this.setState({
      editCourseName: '',
      editCourseDescription: '',
      editCourseMeetingTimes: '',
      editCourseStartDate: '',
      editCourseEndDate: '',
      showEditClassModal: false,
      editCourseVisibility: '',
      editCourseIsDraft: false,
      editCourseGolferLimit: 1,
      errors: {},
    })
  }

  componentWillMount() {
    this.setState({
      courseLoader: true,
      scheduleLoader: true,
    })

    const courseIdAsInt = parseInt(this.props.params.id, 10)

    this.props.course_actions.getCourse(courseIdAsInt).then((res) => {
      this.props.schedule_actions.getCourseSchedule(res.course.id).then(() => {
        this.setCurrentCourse(this.props.course)
        this.props.resource_actions.getResources(courseIdAsInt)
        this.setState({ courseLoader: false, scheduleLoader: false })
      })
    })

    if (this.props.params.showEdit) {
      this.setState({ showEditClassModal: true })
    }

    if (!this.props.lesson_plans || this.props.lesson_plans.length === 0) {
      this.props.lessonPlan_actions.grabLessonPlanPreview(
        this.props.current_user.type,
        this.props.current_user.id
      )
    }

    //TODO: WE NEED TO LOAD THE RANKS/STUDENTS/OBJECTIVES/ETC AFTER YOU CLICK ON THE TAB
    if (this.props.current_user.type !== 'Admin') {
      this.props.rank_actions.getCourseRanks()
      let rankId = this.props.ranks[0]?.id

      if (!this.props.ranks || this.props.ranks.length === 0) {
        this.props.rank_actions.getAllRanks().then(() => {
          rankId = this.props.ranks[0].id
          this.setState({ rankId })
          this.props.objective_actions.getAllRankObjectives(rankId)
        })
      } else if (!this.props.objectives[rankId] || this.props.objectives[rankId].length === 0) {
        this.setState({ rankId: this.props.ranks[0].id })
        this.props.objective_actions.getAllRankObjectives(rankId)
      } else {
        this.setState({ rankId })
      }
    }
  }

  componentDidMount() {
    const { common_actions } = this.props
    common_actions.showSideBar(true)
    common_actions.showNavBar(true)
    this.setState({ _isMounted: true })

    if (this.props.location.state?.showLiveModal) {
      this.setState({ showLiveModal: true })
      window.history.replaceState({}, document.title)
    }
  }

  componentWillUnmount() {
    this.setState({ _isMounted: false })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.course) {
      if (
        !this.state.course.id ||
        this.state.course.id === nextProps.course.id
      ) {
        //check for first time or updating cou
        this.setCurrentCourse(nextProps.course)
      }
    }

    const rankId = nextProps.ranks[0]?.id
    //Handle Objectives and Enrollments Loading
    if (nextProps.objectives[rankId] && !_.isEmpty(this.state.currentEnrollment)) {
      const incompleteObjectives = []
      //if there are objs lets chech which are completed
      if (nextProps.objectives[rankId].length > 0) {
        nextProps.objectives[rankId].map((objective) => {
          // eslint-disable-line
          if (objective.is_completed === false) {
            incompleteObjectives.push(objective)
          }
        })
        if (incompleteObjectives.length > 0) {
          //set state of incomplete objectives and select the first one
          this.setState({
            incompleteObjectives: incompleteObjectives,
            issueTrophyFormObjectiveId: incompleteObjectives[0].id,
          })
        }
      }
      this.setState({
        gotObjectivesForUser: this.state.currentEnrollment.user_id,
      })
    }
  }

  sortSesssions = (sessions) => {
    if (sessions) {
      sessions.sort((a, b) => {
        if (a.startDate > b.startDate) {
          return 1
        } else if (a.startDate < b.startDate) {
          return -1
        }

        if (a.index < b.index) {
          return 1
        } else if (a.index > b.index) {
          return -1
        } else {
          return 0
        }
      })
    }
  }

  updateSessions = () => {
    const sessions = this.state.course?.schedule?.scheduleSessions?.map((s, index) => (
      {
        ...s,
        index: index,
        startDate: moment(s.startDate),
        endDate: moment(s.endDate),
      }
    ))

    this.sortSesssions(sessions)

    if (!sessions || sessions.length === 0) {
      return
    }

    this.setState({
      course: {
        ...this.state.course,
        schedule: {
          ...this.state.course.schedule,
          scheduleSessions: sessions,
        },
      },
      sessionIndex: sessions ? sessions.length - 1 : -1,
    })
  }

  setCurrentCourse(course) {
    if (course) {
      this.setState({
        course: course,
        editCourseName: course.name,
        editCourseDescription: course.description,
        editCourseMeetingTimes: course.meeting_times,
        editCourseStartDate: moment(course.start_date),
        editCourseEndDate: moment(course.end_date),
        editCourseVisibility: course.visibility,
        editCourseGolferLimit: course.golfer_limit,
      }, () => this.updateSessions())
    }
  }

  toggleCompleteModal = () => {
    this.setState({
      showCompleteModal: true,
      confirmationMessage: 'Are you sure you want to delete this course? It will permanently delete the enrollments.',
    })
  }

  toggleValidationModal = (success, message) => {
    this.setState({
      showValidationModal: true,
      validationMessage: message,
      validationSuccess: success,
    })
  }

  closeValidationModal = () => {
    this.setState({
      showValidationModal: false,
    })
  }

  closeCompleteModal = () => {
    this.setState({
      showCompleteModal: false,
    })
  }

  /*********
RESOURCES LOGIC
*********/
  resetResourceModal = () => {
    this.setState({
      resource_id: '',
      title: '',
      description: '',
      button_url: '',
      type: '',
      uploadPhotoFileSrc: '',
      isUpdate: false,
    })
  }

  openResourceModal = (type, resource) => {
    if (type === 'update') {
      this.setState({
        resource_id: resource.id,
        title: resource.title,
        description: resource.description,
        button_url: resource.button_url,
        uploadPhotoFileSrc: resource.photo ? resource.photo.file_url : '',
        isUpdate: true,
      })
    } else if (type === 'create') {
      this.resetResourceModal()
    }
    this.setState({ showResourceModal: true })
  }

  closeResourceModal = () => {
    this.setState({ showResourceModal: false })
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleResourceTypeChange = (event) => {
    this.setState({ type: event.target.value })
  }

  onChangePhoto = (event) => {
    if (event.target.value) {
      this.setState({
        uploadPhotoName: event.target.value,
        uploadPhotoFile: event.target.files[0],
        didUploadPhoto: true,
      })
      const reader = new window.FileReader()
      const page = this
      reader.onload = function (e) {
        page.setState({
          uploadPhotoFileSrc: e.target.result,
        })
      }
      reader.readAsDataURL(event.target.files[0])
    }
  }

  submitResource = (resourceType) => {
    const {
      resource_id,
      title,
      description,
      button_url,
      type,
      course,
      didUploadPhoto,
      uploadPhotoFileSrc,
      uploadPhotoFile,
    } = this.state
    const resource = {
      id: resource_id,
      title,
      description,
      button_url,
      course,
      type,
    }

    if (didUploadPhoto && uploadPhotoFileSrc) {
      resource.resource_photo = uploadPhotoFile
    }

    if (resourceType === 'update') {
      this.props.resource_actions.updateResource(resource).then(() => {
        this.props.course_actions.getCourse(course.id)
        this.closeResourceModal()
      })
    } else if (resourceType === 'create') {
      this.props.resource_actions.createResource(resource).then(() => {
        this.props.course_actions.getCourse(course.id)
        this.closeResourceModal()
      })
    }
  }

  openApproveModal(enrollment) {
    const { user_actions } = this.props
    this.setState({ userLoader: true, showApproveModal: true })
    user_actions.getUser(enrollment.user_id).then(() => {
      this.setState({
        currentEnrollment: enrollment,
        issueTrophyFormUserId: enrollment.user_id,
        userLoader: false,
      })
    })
  }

  closeApproveModal() {
    this.setState({
      showApproveModal: false,
      gotObjectivesForUser: -1,
      currentEnrollment: {},
    })
  }

  openEditClassModal(step = '1') {
    this.setState({ editStartStep: step, showEditClassModal: true })
  }

  toggleAddStudentModal() {
    if (this.state.showAddStudentModal) {
      this.setState({ showAddStudentModal: false, activeMembers: [] })
    } else {
      this.setState({ showAddStudentModal: true }, () => {
        this.setState({ studentLoader: true })
        this.props.communities_actions
          .getAllStudents(this.props.current_user.communities[0].id)
          .then(() => {
            this.setState({
              studentLoader: false,
            })
          })
      })
    }
  }

  toggleIssueBadgesModal() {
    if (this.state.showIssueBadgesModal) {
      this.setState({
        showIssueBadgesModal: false,
        activeMembers: [],
        rankId: -1,
        objectiveId: -1,
      })
    } else {
      this.setState({
        showIssueBadgesModal: true,
        activeMembers: [],
        rankId: -1,
        objectiveId: -1,
        studentsLoader: true,
      })
    }
  }

  closeEditClassModal() {
    this.defaultUpdateCourseState()
  }

  handleRankSelectChange(event) {
    this.setState({ rankId: event.target.value })
    this.props.objective_actions.getAllRankObjectives(event.target.value)
  }

  handleObjectiveSelectChange(event) {
    this.setState({ objectiveId: event.target.value })
    const courseIdAsInt = parseInt(this.props.params.id, 10)
    const rankIdAsInt = parseInt(this.state.rankId, 10)
    const objectiveIdAsInt = parseInt(event.target.value, 10)
    //TODO NEED TO HANDLE REMOVING A STUDENT HERE
    this.props.course_actions
      .getCourseStudents(courseIdAsInt, rankIdAsInt, objectiveIdAsInt)
      .then(() => {
        this.setState({
          studentsLoader: false,
          courseStudents: this.props.course_students,
        })
      })
  }

  handleRankObjectiveSelectChanged(changeEvent) {
    this.setState({
      issueTrophyFormObjectiveId: changeEvent.target.value,
    })
  }

  onSubmitBadges(e) {
    e.preventDefault()
    const userString = this.state.activeMembers.join(',')
    this.props.activity_actions
      .postActivityObjectives(
        this.state.rankId,
        this.state.objectiveId,
        userString
      )
      .then(() => {
        this.props.user_actions.getUserCourses(
          this.props.current_user.communities[0].id
        )
        this.setState({
          alertMessage: 'Badges successfully issued.  Good Work Coach!',
          alertTitle: 'Badge Issued',
          alertType: 'success',
        })
      })
    this.defaultIssueBadgesState()
  }

  /************** REDUX FUNCTIONS *****************/
  submitIssueTrophyForm() {
    const { user } = this.props
    const { course, incompleteObjectives, issueTrophyFormObjectiveId } =
      this.state

    const userCurriculum = user.user_curriculums.find(
      (x) => x.program_curriculum_id === course.program_curriculum.id
    )
    let objectivesCopy = incompleteObjectives
    objectivesCopy = objectivesCopy.filter(
      (objective) => objective.id !== issueTrophyFormObjectiveId
    )
    this.setState({ incompleteObjectives: objectivesCopy })
    this.props.activity_actions
      .postActivityObjective(this.state, userCurriculum)
      .then(() => {
        this.props.course_actions.getCourse(course.id).then(() => {
          this.props.user_actions.getUserCourses(
            this.props.current_user.communities[0].id
          )
          toast.success(
            `Success. Issued Badge to ${user.first_name} ${user.last_name}`,
            {
              position: toast.POSITION.BOTTOM_LEFT,
            }
          )
        })
      })
    this.defaultTrophyFormState()
  }

  editCourse(course) {
    this.setState({ courseLoader: true })
    this.props.course_actions.updateCourse(course).then(() => {
      this.setState({ errors: {} })

      if (course.schedule?.scheduleSessions.length) {
        this.props.schedule_actions.getCourseSchedule(course.id).then ((res) => {
          if (res.schedule) {
            this.props.schedule_actions.updateSchedule({ ...course.schedule }).then(() => {
              this.props.course_actions.updateCourseDuration(course.id).then(() => {
                this.setState({ showEditClassModal: false, courseLoader: false })
              })
            })
              .catch((err) => {
                this.setState({ courseLoader: false, errors: err.response.data.errors })
              })
          } else {
            this.props.schedule_actions.createSchedule({ ...course.schedule, courseId: course.id }).then(() => {
              this.props.course_actions.updateCourseDuration(course.id).then(() => {
                this.setState({ showEditClassModal: false, courseLoader: false })
              })
            })
              .catch((err) => {
                this.setState({ courseLoader: false, errors: err.response.data.errors })
              })
          }
        })
      } else {
        this.props.course_actions.getCourse(course.id)
          .then(() => this.setState({ showEditClassModal: false, courseLoader: false }))
      }
    }).catch((err) => {
      this.setState({ errors: err.response.data.errors, editStartStep: '-1' })
      if (err.response.data.errors.packages_ids) {
        this.props.course_actions.getCourse(course.id)
      }
    })
  }

  removeStudent(enrollment_id, course) {
    this.setState({
      removeEnrollmentId: enrollment_id,
      removeLoader: true,
    })
    this.props.coach_actions
      .removeStudentEnrollment(enrollment_id, course)
      .then(() => {
        this.toggleValidationModal(
          true,
          'You have successfully removed the student from the class'
        )
        this.setState({
          showAddStudentModal: false,
          activeMembers: [],
          removeLoader: false,
          removeEnrollmentId: null,
        })
      })
      .catch((err) => {
        if (err.response.status === 422) {
          this.toggleValidationModal(false, 'Could not remove student class')
          this.setState({
            removeLoader: false,
            removeEnrollmentId: null,
          })
        }
      })
  }

  approveEnrollment(enrollmentId) {
    this.setState({ addStudentLoader: true })
    this.props.course_actions.approveEnrollment(enrollmentId).then(() => {
      this.setState({
        addStudentLoader: false,
      })
    })
  }

  updateActiveMembers(newMembers) {
    this.setState({ activeMembers: newMembers })
  }

  handleSubmitUserJoinCourse(course) {
    this.setState({
      addStudentLoader: true,
    })
    this.state.activeMembers.map((user_id) =>
      this.props.coach_actions
        .addStudentEnrollment(user_id, course)
        .then(() => {
          this.toggleValidationModal(
            true,
            'Student(s) have been added to class!'
          )
          this.setState({
            showAddStudentModal: false,
            activeMembers: [],
            addStudentLoader: false,
          })
          this.props.course_actions.getCourse(this.state.course.id)
          return
        })
        .catch(() => {
          this.toggleValidationModal(
            false,
            'Could not add student(s) to class, please try again'
          )
          this.setState({ addStudentLoader: false })
        })
    )
  }

  progressRankForUser() {
    const { user } = this.props
    const userCurriculum = user.user_curriculums.find(
      (x) => x.program_curriculum_id === this.state.course.program_curriculum.id
    )
    this.setState({ isLoading: true })
    this.props.user_actions
      .progressRank(user.id, this.state.course.id)
      .then(() => {
        this.props.course_actions
          .getCourse(this.state.course.id)
          .then(() => {
            this.props.activity_actions
              .postActivityRank(this.state, userCurriculum)
              .then(() => {
                this.props.user_actions
                  .getUserCourses(this.props.current_user.communities[0].id)
                  .then(() => {
                    this.setState({
                      leveledUpUser: true,
                      isRankCompleted: false,
                      isLoading: false,
                      alertMessage:
                        'Student has been moved up to the next level!',
                      alertTitle: 'Success',
                      alertType: 'success',
                    })
                    this.closeApproveModal()
                  })
              })
          })
      })
  }

  checkIsRankCompleted() {
    const { rankId } = this.state
    if (this.props.objectives[rankId].length > 0) {
      let rankCompleted = true
      // eslint-disable-next-line
      this.props.objectives[rankId].map((objective, index) => {
        if (objective.is_completed === false) {
          //this.setState({isRankCompleted: false})
          rankCompleted = false
          return; // eslint-disable-line
        }
      })
      this.setState({ isRankCompleted: rankCompleted, leveledUpUser: false })
    }
  }

  deleteClass = () => {
    if (this.state.course) {
      const course = Object.assign({}, this.state.course)
      course.archived = true
      this.setState({
        courseLoader: true,
        deleteClassLoader: true,
      })
      this.props.course_actions
        .deleteCourse(course.id)
        .then(() => {
          this.props.course_actions
            .getCourse(this.state.course.id)
            .then((response) => {
              this.props.programDeleted(response.course)
              this.setState({
                courseLoader: true,
                deleteClassLoader: false,
              })
            })
          this.closeCompleteModal()
          this.props.navigate('/programs/')
        })
        .catch(() => { })
    }
  }

  /********* RENDER FUNCTIONS ****************/
  displayBadgeModalPopup() {
    const { currentEnrollment, course, gotObjectivesForUser, userLoader, rankId } =
      this.state
    const { user } = this.props

    if (!_.isEmpty(currentEnrollment)) {
      if (!userLoader) {
        const userCurriculum = user.user_curriculums.find(
          (x) => x.program_curriculum_id === course.program_curriculum.id
        )
        if (
          this.props.objectives[rankId].length === 0 ||
          gotObjectivesForUser !== currentEnrollment.user_id
        ) {
          this.props.objective_actions
            .getRankObjectives(
              userCurriculum.rank_id,
              currentEnrollment.user_id,
              course.id
            )
            .then(() => {
              this.checkIsRankCompleted()
              this.setState({
                rankId: userCurriculum.rank_id,
              })
            })
        }

        return (
          <Modal
            isOpen={this.state.showApproveModal}
            onRequestClose={this.closeApproveModal}
            contentLabel='Issue Badge'
          >
            <div className='modal-header row'>
              <div className='col-11'>
                <h5 className='modal-title display-4'>Review Goals</h5>
                <h3 className='font-200'>
                  <strong>
                    {' '}
                    {user.first_name} - Rank {userCurriculum.rank_id}
                  </strong>
                </h3>
              </div>
              <div className='col-1'>
                <button
                  type='button'
                  className='close pull-right font-200'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={this.closeApproveModal}
                >
                  <span aria-hidden='true'>✕</span>
                </button>
              </div>
            </div>
            <IssueTrophy
              objectives={this.props.objectives[this.state.rankId]}
              incompleteObjectives={this.state.incompleteObjectives}
              isLoading={this.state.isLoading}
              isRankCompleted={this.state.isRankCompleted}
              userCurriculum={userCurriculum}
              currentEnrollment={this.state.currentEnrollment}
              issueTrophyFormObjectiveId={this.state.issueTrophyFormObjectiveId}
              handleRankObjectiveSelectChanged={
                this.handleRankObjectiveSelectChanged
              }
              submitIssueTrophyForm={this.submitIssueTrophyForm}
              progressRankForUser={this.progressRankForUser}
            />
          </Modal>
        )
      }
    } else {
      return (
        <Modal
          isOpen={this.state.showApproveModal}
          onRequestClose={this.closeApproveModal}
          contentLabel='Issue Badge'
        >
          <div className='modal-header row'>
            <div className='col-11'>
              <h5 className='modal-title display-4'>Review Goals</h5>
              <h3 className='font-200'>
                <strong> Loading Golfer</strong>
              </h3>
            </div>
            <div className='col-1'>
              <button
                type='button'
                className='close pull-right font-200'
                data-dismiss='modal'
                aria-label='Close'
                onClick={this.closeApproveModal}
              >
                <span aria-hidden='true'>✕</span>
              </button>
            </div>
          </div>
          <div className='pt-5'>
            <Loader message='loading objectives' />
          </div>
        </Modal>
      )
    }
  }

  displayAddStudentsModal() {
    return (
      <Modal
        isOpen={this.state.showAddStudentModal}
        onRequestClose={this.toggleAddStudentModal}
        contentLabel='Add Students'
      >
        <div className='modal-header row'>
          <div className='col-11'>
            <h5 className='modal-title display-4'>Add Students</h5>
          </div>
          <div className='col-1'>
            <button
              type='button'
              className='close pull-right font-200'
              data-dismiss='modal'
              aria-label='Close'
              onClick={this.toggleAddStudentModal}
            >
              <span aria-hidden='true'>✕</span>
            </button>
          </div>
        </div>
        {this.state.studentLoader ? (
          <div
            className=''
            style={{ marginTop: '-40%', marginBottom: '50px', width: '100%' }}
          >
            <Loader message='loading golfers' />
          </div>
        ) : (
          <AddStudents
            course={this.state.course}
            handleSubmitUserJoinCourse={this.handleSubmitUserJoinCourse}
            communityStudents={this.props.community_students}
            activeMembers={this.state.activeMembers}
            loader={this.state.addStudentLoader}
            updateActiveMembers={this.updateActiveMembers}
          />
        )}
      </Modal>
    )
  }

  displayIssueBadgesModal() {
    return (
      <Modal
        isOpen={this.state.showIssueBadgesModal}
        onRequestClose={this.toggleIssueBadgesModal}
        contentLabel='Mass Review Goals'
      >
        <div className='modal-header row'>
          <div className='col-11'>
            <h5 className='modal-title display-4'>Mass Review Goals</h5>
          </div>
          <div className='col-1'>
            <button
              type='button'
              className='close pull-right font-200'
              data-dismiss='modal'
              aria-label='Close'
              onClick={this.toggleIssueBadgesModal}
            >
              <span aria-hidden='true'>✕</span>
            </button>
          </div>
        </div>
        <IssueBadges
          isLoading={this.state.isLoading}
          isRankCompleted={this.state.isRankCompleted}
          currentEnrollment={this.state.currentEnrollment}
          incompleteObjectives={this.state.incompleteObjectives}
          course={this.state.course}
          studentsLoader={this.state.studentsLoader}
          handleSubmitUserJoinCourse={this.handleSubmitUserJoinCourse}
          handleRankSelectChange={this.handleRankSelectChange}
          handleObjectiveSelectChange={this.handleObjectiveSelectChange}
          onSubmitBadges={this.onSubmitBadges}
          objectives={this.props.objectives[this.state.rankId]}
          ranks={this.props.ranks}
          rankId={this.state.rankId}
          objectiveId={this.state.objectiveId}
          communityStudents={this.props.community_students}
          courseStudents={this.state.courseStudents}
          activeMembers={this.state.activeMembers}
          updateActiveMembers={this.updateActiveMembers}
        />
      </Modal>
    )
  }

  getTabs() {
    const tabStyle = {
      barColor: '#ff6633',
      width: '100%',
      borderRadius: '5px',
    }
    if (this.props.current_user.type === 'Admin') {
      return (
        <Tabs>
          <TabList className='react-tabs__tab-list row seg-control-tab-extended primary-color-bg tab-container'>
            <Tab className='react-tabs__tab col text-center' {...tabStyle}>LESSON PLAN</Tab>
          </TabList>
          <TabPanel className='seg-control-content'>
            {this.state.course && this.state.course.lesson_plan ? (
              <div
                style={{
                  border: '1px solid black',
                  borderRadius: '5px',
                  overflow: 'hidden',
                  marginBottom: '15px',
                  height: '1px',
                  minHeight: 'calc(100vh - 277px)',
                }}
              >
                <LessonReviewPublish
                  fromProgramPage={true}
                  isBuilder={false}
                  sessions={JSON.parse(
                    this.state.course.lesson_plan.draft_sessions
                  )}
                  triggerCurriculumDetailModal={
                    this._triggerCurriculumDetailModal
                  }
                  triggerDrillDetailModal={this._triggerDrillDetailModal}
                  lessonPlanName={this.state.course.lesson_plan.name}
                  lessonPlanNotes={this.state.course.lesson_plan.notes}
                  lessonPlanTags={this.state.course.lesson_plan.tags}
                  lessonPlanPhoto={
                    this.state.course.lesson_plan.photo
                      ? this.state.course.lesson_plan.photo.file_url
                      : this.state.course.lesson_plan.template_photo_url || ''
                  }
                  video_url={this.state.course.lesson_plan.video_url}
                  drills={this.state.course.lesson_plan.tasks.filter(
                    (t) => !!t.title
                  )}
                  subjects={this.state.course.lesson_plan.tasks.filter(
                    (t) => !!t.name
                  )}
                />
              </div>
            ) : (
              <div
                className='d-flex justify-content-center align-items-center w-70 mx-auto p-3'
                style={{
                  border: '3px solid #ccc',
                  color: '#ccc',
                  width: '70%',
                }}
              >
                <p className='text-center m-0'>
                  Looks like this class doesn&apos;t have an associated lesson
                  plan, or the corresponding lesson plan was deleted. You can
                  add one by editing the class.
                </p>
              </div>
            )}
          </TabPanel>
        </Tabs>
      )
    } else {
      return (
        <Tabs>
          <TabList className='react-tabs__tab-list row seg-control-tab seg-control-tab-extended tab-container'>
            <Tab className='react-tabs__tab col text-center' {...tabStyle}>GOLFERS</Tab>
            <Tab className='react-tabs__tab col text-center' {...tabStyle}>LESSON PLAN</Tab>
            <Tab className='react-tabs__tab col text-center' {...tabStyle}>SCHEDULE</Tab>
            <Tab className='react-tabs__tab col text-center' {...tabStyle}>LEADERBOARD</Tab>
            <Tab className='react-tabs__tab col text-center' {...tabStyle}>ACTIVITY FEED</Tab>
          </TabList>
          <TabPanel className='seg-control-content'>
            {!_.isEmpty(this.state.course) && (
              <GolfersTabContainer
                course={this.state.course}
                courseLoader={this.state.courseLoader}
                toggleAddStudentModal={this.toggleAddStudentModal}
              />
            )}
            {this.state.showApproveModal && this.displayBadgeModalPopup()}
            {this.displayAddStudentsModal()}
            {this.displayIssueBadgesModal()}
          </TabPanel>
          <TabPanel className='seg-control-content'>
            {this.state.course && this.state.course.lesson_plan ? (
              <div
                style={{
                  border: '1px solid black',
                  borderRadius: '5px',
                  overflow: 'hidden',
                  marginBottom: '10px',
                  height: '1px',
                  minHeight: 'calc(100vh - 277px)',
                }}
              >
                <LessonReviewPublish
                  fromProgramPage={true}
                  isBuilder={false}
                  sessions={JSON.parse(
                    this.state.course.lesson_plan.draft_sessions
                  )}
                  triggerCurriculumDetailModal={
                    this._triggerCurriculumDetailModal
                  }
                  triggerDrillDetailModal={this._triggerDrillDetailModal}
                  lessonPlanName={this.state.course.lesson_plan.name}
                  lessonPlanNotes={this.state.course.lesson_plan.notes}
                  lessonPlanTags={this.state.course.lesson_plan.tags}
                  lessonPlanPhoto={
                    this.state.course.lesson_plan.photo
                      ? this.state.course.lesson_plan.photo.file_url
                      : this.state.course.lesson_plan.template_photo_url || ''
                  }
                  drills={this.state.course.lesson_plan.tasks.filter(
                    (t) => !!t.title
                  )}
                  subjects={this.state.course.lesson_plan.tasks.filter(
                    (t) => !!t.name
                  )}
                // drills={[]}
                // subjects={[]}
                />
              </div>
            ) : (
              <div
                className='d-flex justify-content-center align-items-center flex-column w-70 mx-auto p-3'
                style={{
                  // border: "3px solid #ccc",
                  color: '#bbb',
                  width: '70%',
                }}
              >
                <p className='text-center m-0'>
                  Lesson Plans are now built separate from a class in the
                  enhanced builder. The new builder will allow you to use the
                  same plan for multiple programs, as well as create and save
                  your own templates. In the future you will be able to create
                  your own activities and videos in the builder. To get
                  started either attach a Lesson Plan, or go create one.
                </p>
                <div className='mt-4 d-flex w-100 align-items-center justify-content-between px-5'>
                  <button
                    style={{
                      borderRadius: 500,
                      boxShadow: '5px 8px 10px -10px rgba(0, 0, 0, 0.4)',
                    }}
                    onClick={() => this.props.navigate(`/programs/${this.props.course.id}/edit?step=1`)}
                  >
                    Attach a Lesson Plan
                  </button>
                  <div
                    style={{
                      height: '200px',
                      width: '200px',
                      borderRadius: '50%',
                      backgroundColor: '#bbb',
                      backgroundImage: `url(${lp_icon})`,
                      backgroundSize: '80%',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                  />
                  <button
                    style={{
                      borderRadius: 500,
                      boxShadow: '5px 8px 10px -10px rgba(0, 0, 0, 0.4)',
                    }}
                    onClick={() => this.props.navigate('/lesson-plan')}
                  >
                    Create a Lesson Plan
                  </button>
                </div>
              </div>
            )}
          </TabPanel>
          <TabPanel className='seg-control-content'>
            <ClassScheduleView
              course={this.state.course}
              openEditClassModal={this.openEditClassModal}
              loader={this.state.scheduleLoader}
            />
          </TabPanel>
          <TabPanel className='seg-control-content'>
            <LeaderboardPage web={true} course={this.state.course} />
          </TabPanel>
          <TabPanel className='seg-control-content'>
            <div className='row'>
              <div className='col-md-8 mx-auto'>
                <ActivityFeedPage web={true} course={this.state.course} />
              </div>
            </div>
          </TabPanel>
        </Tabs>
      )
    }
  }

  _triggerCurriculumDetailModal = (id) => {
    // const { subjects } = this.props;
    const subjects = this.props.course.lesson_plan.tasks
    const selectedSubject = subjects.find((sub) => (sub.id === id && sub.name))

    this.setState({ selectedSubject }, () => {
      this.setState({ curriculumModal: true })
    })
  }

  _closeCurriculumModal = () => {
    this.setState({ curriculumModal: false, selectedSubject: {} })
  }

  _triggerDrillDetailModal = (id) => {
    this.setState({ selectedDrillId: id })
  }

  _closeDrillModal = () => {
    this.setState({ selectedDrillId: -1 })
  }

  render() {
    return (
      checkLicensePermission(this.licenseType, ['standard', 'standard_legacy']) ||
      (this.props.current_user.communities[0].selected_stripe_connected_account &&
        this.props.current_user.communities[0].available_packages_count > 0)
        ?
        <div id='ClassesDetailPage'>
          <ClassDetailHeader
            course={this.state.course}
            openEditClassModal={this.openEditClassModal}
            deleteClass={this.toggleCompleteModal}
            archiveProgram={this.deleteClass}
            loader={this.state.courseLoader}
            admin={this.props.current_user.type === 'Admin'}
          />

          {this.getTabs()}
          <ResourceModal
            title={this.state.title}
            type={this.state.type}
            description={this.state.description}
            button_url={this.state.button_url}
            handleResourceTypeChange={this.handleResourceTypeChange}
            handleInputChange={this.handleInputChange}
            submitResource={this.submitResource}
            uploadPhotoFileSrc={this.state.uploadPhotoFileSrc}
            uploadPhotoName={this.state.uploadPhotoName}
            selectInputFile={this.selectInputFile}
            isUpdate={this.state.isUpdate}
            onChangePhoto={this.onChangePhoto}
            openModal={this.openResourceModal}
            closeModal={this.closeResourceModal}
            showModal={this.state.showResourceModal}
          />
          <ValidationModal
            validationSuccess={this.state.validationSuccess}
            showValidationModal={this.state.showValidationModal}
            closeValidationModal={this.closeValidationModal}
            validationMessage={this.state.validationMessage}
          />
          <ConfirmationModal
            showConfirmationModal={this.state.showCompleteModal}
            closeConfirmationModal={this.closeCompleteModal}
            confirmationMessage={this.state.confirmationMessage}
            confirmationDenied={this.closeCompleteModal}
            confirmationProceed={this.deleteClass}
          />
          {this.state.curriculumModal && (
            <CurriculumDetailModal
              hasSideNav={true}
              subject={this.state.selectedSubject}
              close={this._closeCurriculumModal}
            />
          )}

          {this.state.selectedDrillId > 0 && (
            <DrillDetailModal
              drillId={this.state.selectedDrillId}
              currentUser={this.props.current_user}
              close={this._closeDrillModal}
              drillsPage={false}
              handlePin={() => false}
              handleFavorite={() => false}
              currentPage={'classDetailPage'}
            />
          )}

          {!this.state.courseLoader && (
            <LiveModal
              program={this.state.course}
              showModal={this.state.showLiveModal}
              closeModal={() => this.setState({ showLiveModal: false })}
              onClose={() => this.setState({ showLiveModal: false })}
            />
          )}
        </div>
        : <UnavailableClassesPage />
    )
  }
}

ClassDetailPage.propTypes = {
  objectives: PropTypes.arrayOf(PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
  })),
  ranks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  objective_actions: PropTypes.shape({
    getAllRankObjectives: PropTypes.func,
    getRankObjectives: PropTypes.func,
  }),
  course_actions: PropTypes.shape({
    getCourse: PropTypes.func,
    updateCourse: PropTypes.func,
    updateCourseDuration: PropTypes.func,
    approveEnrollment: PropTypes.func,
    deleteCourse: PropTypes.func,
    getCourseStudents: PropTypes.func,
  }),
  course: PropTypes.shape({
    id: PropTypes.number,
    lesson_plan: PropTypes.shape({
      tasks: PropTypes.array,
    }),
  }),
  navigate: PropTypes.func,
  params: PropTypes.shape({
    id: PropTypes.string,
    showEdit: PropTypes.bool,
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      showLiveModal: PropTypes.bool,
    }),
  }),
  current_user: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.number,
    communities: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      license_type: PropTypes.string,
      representative_name: PropTypes.string,
      student_count: PropTypes.number,
      created_at: PropTypes.string,
      timezone: PropTypes.string,
      selected_stripe_connected_account: PropTypes.object,
      available_packages_count: PropTypes.number,
    })),
  }),
  user: PropTypes.shape({
    user_curriculums: PropTypes.array,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    id: PropTypes.number,
  }),
  community_students: PropTypes.array,
  coach_actions: PropTypes.shape({
    addStudentEnrollment: PropTypes.func,
    removeStudentEnrollment: PropTypes.func,
  }),
  activity_actions: PropTypes.shape({
    postActivityObjectives: PropTypes.func,
    postActivityObjective: PropTypes.func,
    postActivityRank: PropTypes.func,
  }),
  user_actions: PropTypes.shape({
    getUser: PropTypes.func,
    getUserCourses: PropTypes.func,
    progressRank: PropTypes.func,
  }),
  resource_actions: PropTypes.shape({
    getResources: PropTypes.func,
    updateResource: PropTypes.func,
    createResource: PropTypes.func,
  }),
  schedule_actions: PropTypes.shape({
    getCourseSchedule: PropTypes.func,
    updateSchedule: PropTypes.func,
    createSchedule: PropTypes.func,
  }),
  course_students: PropTypes.array,
  common_actions: PropTypes.shape({
    showSideBar: PropTypes.func,
    showNavBar: PropTypes.func,
  }),
  communities_actions: PropTypes.shape({
    getAllStudents: PropTypes.func,
  }),
  lesson_plans: PropTypes.array,
  lessonPlan_actions: PropTypes.shape({
    grabLessonPlanPreview: PropTypes.func,
  }),
  rank_actions: PropTypes.shape({
    getCourseRanks: PropTypes.func,
    getAllRanks: PropTypes.func,
  }),
  programDeleted: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    ui: state.ui,
    current_user: state.user.current_user,
    user: state.user.user,
    objectives: state.objectives,
    courses: state.courses.courses,
    completed_courses: state.courses.completed_courses,
    course: state.courses.course,
    users: state.users,
    community_students: state.communityStudents,
    course_students: state.courses.students,
    current_enrollment: state.current_enrollment,
    ranks: state.ranks,
    lesson_plans: state.lessonPlans.previews,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    coach_actions: bindActionCreators(coachActions, dispatch),
    common_actions: bindActionCreators(commonActions, dispatch),
    course_actions: bindActionCreators(courseActions, dispatch),
    resource_actions: bindActionCreators(resourceActions, dispatch),
    objective_actions: bindActionCreators(objectiveActions, dispatch),
    activity_actions: bindActionCreators(activityActions, dispatch),
    leaderboard_actions: bindActionCreators(leaderboardActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    communities_actions: bindActionCreators(communitiesActions, dispatch),
    rank_actions: bindActionCreators(rankActions, dispatch),
    lessonPlan_actions: bindActionCreators(lessonPlanActions, dispatch),
    schedule_actions: bindActionCreators(scheduleActions, dispatch),
    programDeleted: (course) => dispatch(programDeleted({ course })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(ClassDetailPage))
