import PropTypes from 'prop-types'
import classnames from 'classnames'
import { ReactComponent as GolferIcon } from '../../../assets/images/common/icons/golfer-half-icon.svg'
import styles from './EmptyProgramsList.module.scss'

const EmptyProgramsList = ({ title, subtitle }) => (
  <div className={classnames('d-flex flex-column align-items-center', styles.emptyProgramsList)}>
    <GolferIcon className={styles.icon}/>
    <p className={styles.title}>{title}</p>
    <p className={styles.subtitle}>{subtitle}</p>
  </div>
)

EmptyProgramsList.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default EmptyProgramsList
