import { useSelector } from 'react-redux'

import '../../assets/styles/components/common/ReactTabs.scss'
import '../../assets/styles/components/classes/ClassesPage.scss'
import { checkLicensePermission } from '../../utility/communityLicense'
import ParentClassesPage from './ParentClassesPage'
import UnavailableClassesPage from './UnavailableClassesPage'
import CoachProgramsPage from './GroupPrograms/CoachProgramsPage'
import FourOhFour from '../common/FourOhFour'

const ClassesPage = () => {
  const current_user = useSelector(state => state.user.current_user)

  const community = current_user?.communities[0]
  const permittedLicenseType = checkLicensePermission(community?.license_type, ['standard', 'standard_legacy'])
  const stripePaymentsReady = community?.selected_stripe_connected_account && community?.available_packages_count > 0

  if (current_user.type === 'Admin'){
    return <FourOhFour/>
  }

  return (
    <div className='animated fadeIn'>
      {current_user.type === 'Coach'
        ? permittedLicenseType || stripePaymentsReady
          ? <CoachProgramsPage />
          : <UnavailableClassesPage />
        : <ParentClassesPage />
      }
    </div>
  )
}

export default ClassesPage
