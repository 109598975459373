import classnames from 'classnames'
import { Collapse } from 'reactstrap'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as CollapseIcon } from '../../../assets/images/common/icons/arrowDown.svg'
import styles from './CourseCategoryCollapse.module.scss'

const CourseCategoryCollapse = ({ category }) => {
  const [collapseOpen, setCollapseOpen] = useState(false)
  return (
    <>
      <h2 className={classnames('my-3', styles.categoryName)}>
        <button
          className={styles.categoryNameButton}
          onClick={() => setCollapseOpen(!collapseOpen)}
          disabled={category.id === null}
        >
          {category.name}
          {category.id !== null && <CollapseIcon className={classnames(styles.icon, { [styles.iconCollapsed]: collapseOpen })}/>}
        </button>
      </h2>
      <Collapse isOpen={collapseOpen}>
        <div className='d-flex flex-column flex-lg-row'>
          {!!category.headerImage && (
            <img
              src={category.headerImage.fileUrl}
              alt='category header'
              className={classnames('flex-shrink-0', styles.headerImage)} />
          )}
          <div className={classnames('mt-3 mt-lg-0 ml-lg-3', styles.description)}>{category.description}</div>
        </div>
      </Collapse>
    </>
  )
}

CourseCategoryCollapse.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    headerImage: PropTypes.shape({
      fileUrl: PropTypes.string,
    }),
    description: PropTypes.string,
  }),
}

export default CourseCategoryCollapse
