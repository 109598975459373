/* eslint-disable react/prop-types */
import * as React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import ScheduleSessionFormContainer from './ScheduleSessionFormContainer'
import moment from 'moment-timezone'

import './ScheduleSessionCard.scss'
import { useSelector } from 'react-redux'

const ScheduleSessionCard = ({
  sessions,
  handleScheduleSessionClick,
  handleScheduleEditClick,
  addScheduleSession,
  editScheduleSession,
  removeScheduleSession,
  editable,
  scheduleAction,
  communityTimezone,
}) => {
  const [editingKey, setEditingKey] = useState(-1)
  const [selectedScheduleSession, setSelectedScheduleSession] = useState({})
  const currentUserCommunityTimezone = useSelector(state => state.user.current_user?.communities[0]?.timezone)
  const timezone = communityTimezone ?? currentUserCommunityTimezone

  const formatTimeRange = (start, end) => `${moment(start).tz(timezone).format('hh:mm a')} - ${moment(end).tz(timezone).format('hh:mm a')}`

  const handleEditClick = (e, session) => {
    if (handleScheduleEditClick) {
      handleScheduleEditClick(e, session)
    } else {
      e.stopPropagation()
      setSelectedScheduleSession(session)
      setEditingKey(session.index)
    }
  }

  const closeEditMode = () => {
    setEditingKey(-1)
  }

  const filteredSessions = sessions.filter(s => !s.deleted)

  if (filteredSessions.length === 0) {
    return null
  }

  return (
    <div className='schedule-session-card'>
      {sessions[0].startDate ?
        <div className='col-x date-col'>
          <span className='month-name'>{moment(sessions[0].startDate).tz(timezone).format('MMM')}</span>
          <span className='day-number'>{moment(sessions[0].startDate).tz(timezone).format('DD')}</span>
          <span>{moment(sessions[0].startDate).tz(timezone).format('ddd')}</span>
        </div>
        :
        <div className='col-x date-col'>
          <span className='month-name'>No date</span>
        </div>
      }
      <div className='col vl'>
        {filteredSessions.map((session, index) => (
          <React.Fragment key={index}>
            {editingKey === session.index ? (
              <ScheduleSessionFormContainer
                selectedScheduleSession={selectedScheduleSession}
                scheduleAction={scheduleAction}
                handleCloseForm={closeEditMode}
                addScheduleSession={addScheduleSession}
                editScheduleSession={editScheduleSession}
                removeScheduleSession={removeScheduleSession}
              />
            ) : (
              <div key={session.id}
                onClick={() => handleScheduleSessionClick ? handleScheduleSessionClick(session) : null}
                onKeyDown={() => handleScheduleSessionClick ? handleScheduleSessionClick(session) : null}
                role='button'
                tabIndex={0}
              >
                <div className='d-flex mb-2 justify-content-between'>
                  <p className='title'>
                    {session?.title ? session.title : ''}
                  </p>
                  {editable &&
                    <i className='fa fa-pencil-square-o edit-icon'
                      onClick={(e) => handleEditClick(e, session)}
                      onKeyDown={(e) => handleEditClick(e, session)}
                      role='button'
                      tabIndex={0}
                    />}
                </div>
                {session.startDate && session.endDate && (
                  <div className='d-flex flex-row align-items-center mb-2 time-range'>
                    <span className='icon-wrapper'><i className='fa fa-clock-o icon' /></span>
                    <p>{formatTimeRange(session.startDate, session.endDate)}</p>
                  </div>
                )}
                {session?.location && (
                  <div className='d-flex flex-row align-items-center mb-2'>
                    <span className='icon-wrapper'><i className='fa fa-map-marker icon' /></span>
                    <p>{session.location}</p>
                  </div>
                )}
                {session?.description && (
                  <p className='description'>
                    {session.description}
                  </p>
                )}
              </div>
            )}
            {index < filteredSessions.length - 1 && <div className='hl' />}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

ScheduleSessionCard.propTypes = {
  session: PropTypes.shape({
    title: PropTypes.string,
    location: PropTypes.string,
    description: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      location: PropTypes.string,
      description: PropTypes.string,
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date),
    })
  ),
  communityTimezone: PropTypes.string,
}

export default ScheduleSessionCard
