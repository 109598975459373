import SubmitButton from '../../common/buttons/SubmitButton'
import moment from 'moment-timezone'
import './ScheduleView.scss'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const ScheduleView = ({ session, setScheduleAction, closeModal, editable }) => {
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)
  const formatTimeRange = (start, end) => `${moment(start).tz(timezone).format('hh:mm a')} - ${moment(end).tz(timezone).format('hh:mm a')}`

  return (
    <div className='schedule-view' key={session.id}>
      <div className='d-flex justify-content-between'>
        { session?.title ? <p className='title' style={{marginRight: editable ? '-25%' : '0'}}>{session?.title}</p> : <p/> }
        {editable && (
          <span className='edit-icon' onClick={() => setScheduleAction('update')}>
            <i className='fa fa-pencil-square-o'/> Edit session
          </span>
        )}
      </div>
      <p><i className='fa fa-calendar'/><span className='orange-bold'>{moment(session.startDate).tz(timezone).format('LLLL')
        .slice(0, -8)}</span></p>
      {session.startDate && session.endDate &&
        <p><i className="fa fa-clock-o icon"/><span className='orange-bold'>{formatTimeRange(session.startDate, session.endDate)}</span></p>
      }
      { session?.location &&
      <div className='d-flex align-items-center mb-3'>
        <i className="fa fa-map-marker icon"/>
        <p className='mb-0'>{session.location}</p>
      </div> }
      <div className='description'>{session?.description ? session.description : '(No aditional details)'}</div>
      <SubmitButton buttonMessage='Close' onClick={() => closeModal()}/>
    </div>
  )
}

ScheduleView.propTypes = {
  session: PropTypes.shape({
    title: PropTypes.string,
    location: PropTypes.string,
    description: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
}

export default ScheduleView
