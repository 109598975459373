import { UncontrolledPopover } from 'reactstrap'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { ReactComponent as TableSelectDropDownIcon } from '../../../assets/images/common/icons/table-select-drop-down-icon.svg'
import colors from '../../../assets/styles/globals.scss'
import styles from './CoachProgramsPage.module.scss'

const HeaderActionButton = ({ showModal, showCopyProgramModal }) => (
  <div>
    <button id='buildProgramButton' className={classnames('btn btn-primary pl-3 pr-3', styles.buildProgramButton)}>
      <div className='d-flex align-items-center'>
        <span className='mr-2'>Build a Program</span>
        <TableSelectDropDownIcon stroke={colors.lightNavy} style={{ marginLeft: '2px' }}/>
      </div>
    </button>
    {!showModal &&
      <UncontrolledPopover
        placement='bottom'
        target='buildProgramButton'
        trigger='legacy'
        popperClassName='program-popover-style'
      >
        <div className='d-flex flex-column build-program-actions'>
          <Link to={'/programs/new'}>Build a Program</Link>
          <p aria-hidden='true' onClick={showCopyProgramModal}>Copy Existing Program</p>
        </div>
      </UncontrolledPopover>
    }
  </div>
)

HeaderActionButton.propTypes = {
  showModal: PropTypes.bool,
  showCopyProgramModal: PropTypes.func,
}

export default HeaderActionButton
