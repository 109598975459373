import ReactTooltip from 'react-tooltip'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import styles from './EnrollmentsProgressBar.module.scss'
import EnrollmentTooltipStudentRow from './EnrollmentTooltipStudentRow'

const EnrollmentsProgressBar = ({ id, students, capacity, appliedEnrollments }) => (
  <>
    <div className={styles.golfersInfo} data-for={`enrollmentsTooltip-${id}`} data-tip>
      <div className={styles.golfersCount}>
        {capacity ?
          students.length === 0 ?
            <>No Enrollments</>
            :
            <>
              <span>{students.length}</span>
              /
              {capacity} golfers
            </>
          :
          <><span className={styles.count}>{students.length}</span> golfers</>
        }
      </div>
      {capacity &&
        <div className={classnames('progress', styles.progressBackground)}>
          <div
            className={styles.progressBar}
            role='progressbar'
            style={{ width: `${students.length * 100 / capacity}%` }}
            aria-valuenow='25'
            aria-valuemin='0'
            aria-valuemax='100' />
        </div>
      }
    </div>
    <ReactTooltip id={`enrollmentsTooltip-${id}`}
      effect='solid'
      delayUpdate={1000}
      delayHide={100}
      place={'bottom'}
      className={styles.enrollmentsTooltip}
    >
      <div>
        <div className={styles.tooltipSection}>
          <p className={styles.tooltipTitle}>Enrolled: {students.length}</p>
          <p className={styles.tooltipTitle}>Capacity: {capacity ? capacity : 'Not set'}</p>
        </div>
        {appliedEnrollments.length > 0 &&
          <div className={styles.tooltipSection}>
            <p className={styles.tooltipTitle}>Needs Approval:</p>
            {appliedEnrollments.map((enrollment, idx) => {
              const student = students.find((student) => student.id === enrollment.userId)
              return (
                <EnrollmentTooltipStudentRow student={student} key={idx}/>
              )
            })}
          </div>
        }
        <div className={styles.tooltipSection}>
          <p className={styles.tooltipTitle}>Enrolled:</p>
          {students.filter((student) => !appliedEnrollments.map((e) => e.userId).includes(student.id)).map((student, idx) =>
            <EnrollmentTooltipStudentRow student={student} key={idx}/>
          )}
        </div>
      </div>
    </ReactTooltip>
  </>
)

EnrollmentsProgressBar.propTypes = {
  id: PropTypes.number,
  students: PropTypes.array,
  capacity: PropTypes.number,
  appliedEnrollments: PropTypes.array,
}

export default EnrollmentsProgressBar
