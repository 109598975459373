import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Modal from 'react-modal'
import PropTypes from 'prop-types'
import moment from 'moment'

import { ReactComponent as ModalXIcon } from '../../../../assets/images/common/icons/modal-X-icon.svg'
import { addRecurringSessions } from '../../../../reducers/programBuilderReducer'
import { getMomentInCommunityTimezone } from '../../../../utility/datesHelper'
import SubmitButton from '../../../common/buttons/SubmitButton'
import DatePicker from '../../../common/form/DatePicker'
import Input from '../../../common/form/Input'

import styles from './RecurringSessionsModal.module.scss'
import FieldError from '../../../common/FieldError'
import classNames from 'classnames'

const customErrorMessages = {
  startDate: 'Please select the date.',
  endDate: 'Please select a start time and an end time.',
  repeatsEvery: 'Please enter the frequency of the sessions.',
  numberOfSessions: 'Please enter the number of sessions to be created.',
  repeatsOn: 'Please select the week days when the sessions will be created.',
}

// eslint-disable-next-line max-lines-per-function
const RecurringSessionsModal = ({ showModal, closeModal }) => {
  const dispatch = useDispatch()
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [repeatsEvery, setRepeatsEvery] = useState('')
  const [numberOfSessions, setNumberOfSessions] = useState('')
  const [repeatsOn, setRepeatsOn] = useState({})
  const [errors, setErrors] = useState({})

  const handleDateChange = (value) => {
    let newStartDate = startDate ? moment(startDate) : moment(new Date().setMinutes(0))
    newStartDate = moment(moment(value).format('YYYY-MM-DD') + ' ' + newStartDate.format('HH:mm')).toDate()
    let newEndDate = endDate ? moment(endDate) : moment(newStartDate).add(1, 'hours')
    newEndDate = moment(moment(value).format('YYYY-MM-DD') + ' ' + newEndDate.format('HH:mm')).toDate()

    if (newStartDate >= newEndDate) {
      newEndDate = moment(newEndDate).add(1, 'days').toDate()
    }

    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const handleStartTimeChange = (value) => {
    let newStartDate = startDate ? moment(startDate) : moment(new Date())
    newStartDate = moment(newStartDate.format('YYYY-MM-DD') + ' ' + moment(value).format('HH:mm')).toDate()
    let newEndDate = endDate ? moment(endDate) : moment(newStartDate).add(1, 'hours')
    newEndDate = moment(moment(newStartDate).format('YYYY-MM-DD') + ' ' + newEndDate.format('HH:mm')).toDate()

    if (newStartDate >= newEndDate) {
      newEndDate = moment(newEndDate).add(1, 'days').toDate()
    }

    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const handleEndTimeChange = (value) => {
    let newEndDate = startDate ? moment(startDate) : moment(new Date())
    newEndDate = moment(newEndDate.format('YYYY-MM-DD') + ' ' + moment(value).format('HH:mm')).toDate()

    let newStartDate = startDate
    if (!newStartDate) {
      newStartDate = moment(moment(newEndDate).format('YYYY-MM-DD') + ' ' + moment(newEndDate).subtract(1, 'hours').format('HH:mm')).toDate()
    }

    if (newStartDate >= newEndDate) {
      newEndDate = moment(newEndDate).add(1, 'days').toDate()
    }

    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const handleDateTimeChange = e => {
    const { name, value } = e

    if (name === 'date') {
      handleDateChange(value)
    } else if (name === 'startDate') {
      handleStartTimeChange(value)
    } else {
      handleEndTimeChange(value)
    }
  }

  const invalidFields = {
    startDate: startDate === '',
    endDate: endDate === '',
    repeatsEvery: repeatsEvery === '' || parseInt(repeatsEvery) <= 0,
    numberOfSessions: numberOfSessions === '' || parseInt(numberOfSessions) <= 0,
    repeatsOn: Object.keys(repeatsOn).length === 0,
  }

  const formValid = () => {
    const errors = {};

    ['startDate', 'endDate', 'repeatsEvery', 'numberOfSessions', 'repeatsOn'].forEach((type) => {
      if (invalidFields[type]) {
        errors[type] = customErrorMessages[type]
      }
    })

    setErrors(errors)

    return Object.keys(errors).length === 0
  }

  const handleSession = () => {
    if (formValid()) {
      dispatch(addRecurringSessions({
        startDate: getMomentInCommunityTimezone(moment(startDate), timezone, 'YYYY-MM-DD HH:mm:ss'),
        endDate: getMomentInCommunityTimezone(moment(endDate), timezone, 'YYYY-MM-DD HH:mm:ss'),
        repeatsEvery,
        numberOfSessions,
        repeatsOn,
      }))
      exitModal()
    }
  }

  const exitModal = () => {
    setStartDate('')
    setEndDate('')
    setRepeatsEvery('')
    setNumberOfSessions('')
    setRepeatsOn({})
    setErrors({})
    closeModal()
  }

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={exitModal}
      contentLabel='Recurring Sessions Builder Modal'
      className={styles['recurring-sessions-builder-modal-content']}
      autoFocus
    >
      <section className='animated fadeIn'>
        <div className='d-flex justify-content-end text-align-right'>
          <ModalXIcon className={styles['modal-X-icon']} onClick={exitModal} />
        </div>
        <p className={styles.title}>Create Recurring Sessions</p>
        <div className='row'>
          <div className='col-12 col-sm-6 mb-2'>
            <label className={styles.label} htmlFor='date'>
              Start Date <span className='required-text'>*</span>
            </label>

            <DatePicker
              selected={startDate}
              onChange={date => handleDateTimeChange({ name: 'date', value: date })}
              error={errors.startDate}
              symbol={<i className='fa fa-calendar-o'/>}
            />
          </div>

          <div className='col-12 col-sm-6 mb-2'>
            <label className={styles.label} htmlFor='time'>
              Time <span className='required-text'>*</span>
            </label>

            <div className='d-flex'>
              <DatePicker
                selected={startDate}
                className={styles['start-date-input']}
                onChange={time => handleDateTimeChange({ name: 'startDate', value: time })}
                inputClassNames={{
                  inputContainer: classNames('br-0', { [styles['error-input']]: !!errors.startDate || !!errors.endDate }),
                  input: 'w-100 text-right',
                }}
                symbol={<i className='fa fa-clock-o'/>}
                showTimeSelect
                classNames='text-right'
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption='time'
                dateFormat='h:mm aa'
                autoComplete='off'
              />
              <DatePicker
                selected={endDate}
                className={styles['end-date-input']}
                onChange={time => handleDateTimeChange({ name: 'endDate', value: time })}
                inputClassNames={{ inputContainer: classNames('bl-0 pl-0', { [styles['error-input']]: !!errors.startDate || !!errors.endDate }) }}
                symbol={<i className='fa fa-minus'/>}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption='time'
                dateFormat='h:mm aa'
                autoComplete='off'
              />
            </div>

            {errors.endDate && <FieldError message={errors.endDate} />}
          </div>

          <div className='col-12 col-sm-6 mb-2'>
            <label className={styles.label} htmlFor='repeats-every'>
              Repeats Every <span className='required-text'>*</span>
            </label>

            <div className='row d-flex align-items-center'>
              <div className='col-6 col-sm-4'>
                <Input
                  name='repeats-every'
                  type='number'
                  value={repeatsEvery}
                  classNames={{ inputContainer: classNames({ [styles['error-input']]: !!errors.repeatsEvery }) }}
                  onChange={(e) => e.target.value.length <= 3 && setRepeatsEvery(e.target.value)}
                />
              </div>
              <div className={`col-6 col-sm-8 pl-0 ${styles.label}`}>
                Weeks
              </div>
            </div>

            {errors.repeatsEvery && <FieldError message={errors.repeatsEvery} />}
          </div>

          <div className='col-12 col-sm-6 mb-2'>
            <label className={styles.label} htmlFor='repeats-every'>
              Number of Sessions  <span className='required-text'>*</span>
            </label>

            <div className='row d-flex align-items-center'>
              <div className='col-6 col-sm-4'>
                <Input
                  name='repeats-every'
                  type='number'
                  value={numberOfSessions}
                  classNames={{ inputContainer: classNames({ [styles['error-input']]: !!errors.repeatsEvery }) }}
                  onChange={(e) => e.target.value.length <= 3 && setNumberOfSessions(e.target.value)}
                />
              </div>
              <div className={`col-6 col-sm-8 pl-0 ${styles.label}`}>
                Sessions
              </div>
            </div>

            {errors.numberOfSessions && <FieldError message={errors.numberOfSessions} />}
          </div>

          <div className='col-12 mb-2'>
            <label className={styles.label} htmlFor='repeats-on'>Repeats On</label>

            <div className='d-flex flex-wrap'>
              {[7, 1, 2, 3, 4, 5, 6].map((day) =>
                <button
                  className={classNames(styles['week-day'], { [styles.selected]: !!repeatsOn[day] })}
                  onClick={() => setRepeatsOn({ ...repeatsOn, [day]: !repeatsOn[day] })}
                  key={day}
                >
                  {moment().isoWeekday(day).format('dddd')[0]}
                </button>
              )}
            </div>

            {errors.repeatsOn && <FieldError message={errors.repeatsOn} />}
          </div>
        </div>
        <div className='row py-3'>
          <div className='col-6'>
            <SubmitButton
              onClick={exitModal}
              buttonMessage='Cancel'
              buttonColor='navy-button'
            />
          </div>
          <div className='col-6'>
            <SubmitButton
              onClick={handleSession}
              buttonMessage='Create Sessions'
              buttonColor='orange-button'
            />
          </div>
        </div>
      </section>
    </Modal>
  )
}

RecurringSessionsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default RecurringSessionsModal
