import { createSlice } from '@reduxjs/toolkit'
import { deleteEnrollment, getEventDetail } from '../actions/eventActions'
import { getLevel, yardageByLevel } from '../utility/curriculumHelper'
import { genderName } from '../utility/userHelper'

const initialState = {
  id: -1,
  selectedEvent: {},
  eventLoader: false,
  deleteLoader: false,
  playTypeLoader: true,
  students: [],
  courses: [],
  enrollments: [],
  holes: 9,
  play_type_id: -1,
  description: '',
  event_date: new Date(),
  location: '',
  name: '',
  isEmail: true,
  uploadPhotoName: '',
  uploadPhotoFile: '',
  uploadPhotoSrc: '',
  indexName: '',
  seriesTitle: '',
  creator_id: -1,
  isStudentError: false,
  isEventError: false,
  status: null,
  errors: {},
}

const nineHoleEventReducer = createSlice({
  name: 'nineHoleEvent',
  initialState,
  reducers: {
    resetState: () => initialState,
    setField: (state, action) => {
      const { field, value } = action.payload
      state[field] = value
    },

    updateFields: (state, action) => {
      const fields = action.payload
      return {
        ...state,
        ...fields,
      }
    },

    addStudent: (state, action) => {
      const std = action.payload
      state.students.push(std)
    },

    addStudents: (state, action) => {
      const students = action.payload
      students?.forEach((std) => {
        state.students.push(std)
      })
    },

    updateStudent: (state, action) => {
      const student = action.payload
      const newStudents = [...state.students]
      const index = newStudents.findIndex(s => s.user_id === student.user_id)
      newStudents[index] = student
      state.students = newStudents
    },

    deleteStudent: (state, action) => {
      const stdId = action.payload
      const index = state.students.findIndex(s => s.user_id === stdId)

      state.students.splice(index, 1)
    },
    addCourse: (state, action) => {
      const course = { ...action.payload, selectedPackages: [] }
      state.courses.push(course)
    },
    updateCourse: (state, action) => {
      const course = action.payload
      const newCourses = [...state.courses]
      const index = newCourses.findIndex(c => c.id === course.id)
      newCourses[index] = course
      state.courses = newCourses
    },
    deleteCourse: (state, action) => {
      const courseId = action.payload
      const index = state.courses.findIndex(c => c.id === courseId)

      state.courses.splice(index, 1)
    },
  },
  extraReducers: {
    // Delete Enrollment
    [deleteEnrollment.fulfilled]: (state, action) => {
      const filteredStudents = state.students.filter(std => std.user_id !== action.payload.seriesEnrollment.userId)
      state.students = filteredStudents
    },
    [deleteEnrollment.rejected]: (state, action) => {
      state.errors = action.payload
    },

    // Get Event
    [getEventDetail.fulfilled]: (state, action) => {
      const event = action.payload.event
      state.id = event.id
      state.name = event.name
      state.event_date = event.event_date
      state.location = event.location
      state.description = event.description
      state.status = event.status
      state.uploadPhotoSrc = event.photo
      state.indexName = event.index_name
      state.seriesTitle = event.series_title
      state.activityEvent = event.activity_event

      state.students = []
      event?.activities?.forEach((activity) => {
        if (activity.type === 'ActivityPlay') {
          const student = activity.user
          state.students.push({
            user_id: student.id,
            current_formal_division: student.current_formal_division,
            milestones: activity.milestones,
            level: getLevel(activity.yardage),
            first_name: student.first_name,
            last_name: student.last_name,
            stats: student.stats,
            score: activity.score,
            greens: activity.greens ? activity.greens : '',
            putts: activity.putts ? activity.putts : '',
            yardage: activity.is_full_tee ? 'fullTee' : activity.yardage,
            fullTeeYardage: activity.is_full_tee ? activity.yardage : '',
            avatar: student.avatar,
            age: student.age,
            gender: genderName[student.gender],
            familyAdminsEmails: student.family_admins_emails,
          })
        }
      })

      event.status !== 'completed' &&
      event?.enrollments?.forEach((e) => {
        const student = e.student
        state.students.push({
          user_id: student.id,
          current_formal_division: student.current_formal_division,
          yardage: yardageByLevel[student.current_formal_division],
          level: student.current_formal_division,
          first_name: student.first_name,
          last_name: student.last_name,
          email: student.email,
          username: student.username,
          stats: student.stats,
          avatar: student.avatar,
          age: student.age,
          gender: genderName[student.gender],
          familyAdminsEmails: student.family_admins_emails,
          course_name: e.course_name,
          addedFrom: { [e.course_id || 'students']: true },
        })
      })
    },
    [getEventDetail.rejected]: (state, action) => {
      state.errors = action.payload
    },
  },
})

export const {
  resetState,
  setField,
  updateFields,
  addStudent,
  addStudents,
  updateStudent,
  deleteStudent,
  addCourse,
  updateCourse,
  deleteCourse,
} = nineHoleEventReducer.actions

export default nineHoleEventReducer.reducer
