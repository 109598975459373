import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Modal from 'react-modal'
import PropTypes from 'prop-types'
import moment from 'moment'

import { getProgramsScheduleSummary } from '../../../../actions/programActions'
import { ReactComponent as ModalXIcon } from '../../../../assets/images/common/icons/modal-X-icon.svg'
import { ReactComponent as BackArrowIcon } from '../../../../assets/images/common/icons/programBuilder/back-arrow-icon.svg'
import { copyScheduleFromProgram } from '../../../../reducers/programBuilderReducer'
import Pagination from '../../../common/Pagination'
import Loader from '../../../common/Op36Loader-web'
import SessionCard from './SessionCard'

import styles from './CopyScheduleModal.module.scss'

const CopyScheduleModal = ({ showModal, closeModal }) => {
  const dispatch = useDispatch()
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)

  const communityId = useSelector(state => state.user.current_user.communities[0].id)
  const { id, communityPrograms, communityProgramsPagination } = useSelector(state => state.programBuilder)

  const [loader, setLoader] = useState(true)
  const [page, setPage] = useState(1)
  const [selectedProgram, setSelectedProgram] = useState(null)

  const fetchCommunityPrograms = useCallback((newPage = 1) => {
    setLoader(true)
    setPage(newPage)

    dispatch(getProgramsScheduleSummary({ filters: { communityId: communityId, notId: id || '' }, page: newPage, perPage: 10 }))
      .unwrap()
      .finally(() => setLoader(false))
  }, [dispatch, communityId, id])

  useEffect(() => {
    fetchCommunityPrograms()
  }, [fetchCommunityPrograms])

  const handleCopySchedule = () => {
    dispatch(copyScheduleFromProgram({ scheduleSessions: selectedProgram.schedule?.scheduleSessions }))
    closeModal()
  }

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      contentLabel='Copy Schedule Builder Modal'
      className={styles['copy-schedule-modal-content']}
      autoFocus
    >
      <section>
        <div className={`d-flex justify-content-between align-items-center sticky-top ${styles['copy-schedule-header']}`}>
          <div
            className={`d-flex justify-content-center align-items-center ${styles['header-back']}`}
            aria-hidden='true'
            onClick={selectedProgram ? () => setSelectedProgram(null) : closeModal}
          >
            <BackArrowIcon aria-hidden='true' id='backArrowIcon' className='flex-shrink-0 ml-2 mr-3' />
            <p className={styles.title}>Copy Schedule from Another Program</p>
          </div>

          <ModalXIcon className={styles['modal-X-icon']} onClick={closeModal} />
        </div>

        {selectedProgram ? (
          <div className={styles['copy-schedule-selected-program-container']}>
            <p className={styles['program-title']}>{selectedProgram.isDraft ? `${selectedProgram.name} [Draft]` : selectedProgram.name}</p>
            <p className={styles['program-schedule']}>
              {selectedProgram.startDate ? moment(selectedProgram.startDate).tz(timezone).format('MMMM DD, YYYY') : 'Not Selected'} -&nbsp;
              {selectedProgram.endDate ? moment(selectedProgram.endDate).tz(timezone).format('MMMM DD, YYYY') : 'Not Selected'}
            </p>

            <div className={styles['selected-program-sessions']}>
              {selectedProgram.schedule?.scheduleSessions?.map((session) => (
                <SessionCard key={session.index} session={session} />
              ))}
            </div>

            <div className={`fixed-bottom ${styles['copy-schedule-footer']}`}>
              <div className='row h-100'>
                <div
                  className='col-12 col-md-6 d-flex justify-content-center justify-content-md-end
                              align-items-end align-items-md-center mb-1 mb-md-0'
                >
                  <button
                    className={`btn btn-primary ${styles['go-back-button']}`}
                    aria-hidden='true'
                    onClick={() => setSelectedProgram(null)}
                  >
                    Go Back
                  </button>
                </div>

                <div
                  className='col-12 col-md-6 d-flex justify-content-center justify-content-md-start
                              align-items-start align-items-md-center mt-1 mt-md-0'
                >
                  <button
                    className={`btn btn-primary ${styles['copy-schedule-button']}`}
                    aria-hidden='true'
                    onClick={handleCopySchedule}
                  >
                    Copy Schedule
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles['copy-schedule-programs-container']}>
            <p className='mb-4'>
              Choose a program you would like to copy. Create a schedule by copying the sessions from another program. The session dates will be
              skipped when copying, so you will need to add them manually afterwards.
            </p>

            {loader
              ? <Loader message='Loading programs' />
              : <>
                <div className={styles.line} />

                <div className={styles['programs-list']}>
                  {communityPrograms.map((program) => (
                    <>
                      <div
                        className={`d-flex justify-content-between align-items-center p-3 ${styles.program}`}
                        aria-hidden='true'
                        onClick={() => { setSelectedProgram(program) }}
                      >
                        <div className='d-flex flex-column'>
                          <p className={styles['program-title']}>{program.isDraft ? `${program.name} [Draft]` : program.name}</p>
                          <p className={styles['program-schedule']}>
                            {program.startDate ? moment(program.startDate).tz(timezone).format('MMMM DD, YYYY') : 'Not Selected'} -&nbsp;
                            {program.endDate ? moment(program.endDate).tz(timezone).format('MMMM DD, YYYY') : 'Not Selected'}
                          </p>
                        </div>

                        <p className={styles['program-sessions']}>{program.schedule?.scheduleSessions?.length || 0} Schedule Sessions</p>
                      </div>

                      <div className={styles.line} />
                    </>
                  ))}
                </div>
              </>
            }

            {communityProgramsPagination?.totalPages > 1 && (
              <div className='row'>
                <Pagination
                  pageCount={communityProgramsPagination.totalPages}
                  fetchData={fetchCommunityPrograms}
                  activePage={page}
                />
              </div>
            )}
          </div>
        )}
      </section>
    </Modal>
  )
}

CopyScheduleModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default CopyScheduleModal
