import { useSelector } from 'react-redux'
import { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import CoachProgramsCard from './CoachProgramsCard'
import InfiniteScroll from '../../infinite-scroll/InfiniteScroll'
import CourseCategoryCollapse from './CourseCategoryCollapse'
import styles from './CoachProgramsPage.module.scss'


const CoachProgramsList = ( { programs, timezone, fetchPrograms, hasMore, isDraft } ) => {
  const { categories } = useSelector(state => state.groupPrograms)
  const [programsByCategory, setProgramsByCategory ] = useState(null)

  useEffect(() => {
    const programsByCategoryMap = {}
    categories.ids.forEach(( categoryId ) => {
      programsByCategoryMap[categoryId] = programs.filter((program) => (program.category?.id || program.category) === categoryId).sort((p1, p2) => {
        const appliedEnrollmentsP1 = p1.enrollments.filter(enrollment => enrollment.status === 'applied').length
        const appliedEnrollmentsP2 = p2.enrollments.filter(enrollment => enrollment.status === 'applied').length
        return appliedEnrollmentsP2 - appliedEnrollmentsP1
      })
    })
    setProgramsByCategory(programsByCategoryMap)
  }, [categories.ids, programs])

  return (
    <div id='coachProgramsList' className={styles.coachProgramsList}>
      <InfiniteScroll
        dataLength={programs.length}
        next={fetchPrograms}
        hasMore={hasMore}
        scrollableTarget='coachProgramsList'
      >
        {
          programsByCategory && categories.ids.map((categoryId) => {
            const category = categoryId ? categories.entities[categoryId] : { id: null, name: 'Other Programs' }
            const programsCards = programsByCategory[categoryId].map((program) =>
              <CoachProgramsCard
                key={program.id}
                program={program}
                timezone={timezone}
                category={category}
                isDraft={isDraft}
              />
            )
            return (
              programsCards.length > 0 &&
              <Fragment key={categoryId}>
                <CourseCategoryCollapse category={category}/>
                {programsCards}
              </Fragment>
            )
          })
        }
      </InfiniteScroll>
    </div>
  )
}

CoachProgramsList.propTypes = {
  programs: PropTypes.array,
  timezone: PropTypes.string,
  fetchPrograms: PropTypes.func,
  hasMore: PropTypes.bool,
  isDraft: PropTypes.bool,
}

export default CoachProgramsList
