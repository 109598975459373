import PropTypes from 'prop-types'
import styles from './EnrollmentsProgressBar.module.scss'
import StockProfilePic from '../../../assets/images/mock/default-profile-icon.png'

const EnrollmentTooltipStudentRow = ({ student }) => (
  <div className={styles.studentRow}>
    <div
      className={styles.profileImg}
      key={student.id}
      style={{
        backgroundImage: student.avatar
          ? `url(${student.avatar.fileUrl})`
          : `url(${StockProfilePic})`,
      }}
    />
    <span className={styles.studentName}>{student.firstName} {student.lastName}</span>
  </div>
)

EnrollmentTooltipStudentRow.propTypes = {
  id: PropTypes.number,
  student: PropTypes.shape({
    id: PropTypes.number,
    avatar: PropTypes.shape({
      fileUrl: PropTypes.string,
    }),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
}

export default EnrollmentTooltipStudentRow
