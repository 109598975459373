import { useDispatch, useSelector } from 'react-redux'

import CoachProgramsFilterBar from './CoachProgramsFilterBar'
import { getCoachPrograms } from '../../../actions/programActions'
import { perPage, selectInitialFilters } from '../../../reducers/groupProgramsReducer'
import CoachProgramsList from './CoachProgramsList'
import { selectFilteredCategory, selectFiltersNumber } from '../../../reducers/groupProgramsReducer'
import NoPrograms from './EmptyProgramsList'
import Loader from '../../common/Op36Loader-web'

const CoachProgramsActiveTab = () => {
  const dispatch = useDispatch()
  const active = useSelector(state => state.groupPrograms.active)
  const initialFilters = useSelector(selectInitialFilters)
  const filtersNumber = useSelector(state => selectFiltersNumber(state, active.filters))
  const filteredCategory = useSelector(state => selectFilteredCategory(state, active.filters?.categoryId))
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)

  const fetchActivePrograms = () => {
    dispatch(getCoachPrograms({
      filters: { ...active.filters, active: 'true', search: active.search },
      page: active.page + 1,
      perPage: perPage,
      withEnrollments: 'true',
    }))
  }

  const handleReloadPrograms = ({ filters, search }) => {
    if (filters) {
      const requestFilters = {
        categoryId: filters.categoryId,
        paymentMethod: filters.paymentMethod,
        visibility: filters.visibility,
        startDate: filters.startDate,
        endDate: filters.endDate,
      }

      dispatch(getCoachPrograms({
        filters: { ...requestFilters, active: 'true', search: active.search },
        page: 1,
        perPage: perPage,
        withEnrollments: 'true',
      }))
    } else if (search !== undefined) {
      dispatch(getCoachPrograms({
        filters: { ...active.filters, active: 'true', search: search },
        page: 1,
        perPage: perPage,
        withEnrollments: 'true',
      }))
    } else {
      dispatch(getCoachPrograms({
        filters: { ...initialFilters, active: 'true', search: active.search },
        page: 1,
        perPage: perPage,
        withEnrollments: 'true',
      }))
    }
  }

  return (
    <>
      <CoachProgramsFilterBar
        filters={active.filters}
        filteredCategory={filteredCategory}
        filtersNumber={filtersNumber}
        handleReloadPrograms={handleReloadPrograms}
        search={active.search}
      />

      {
        active.programs.length > 0 ?
          <CoachProgramsList
            programs={active.programs}
            timezone={timezone}
            hasMore={active.hasMore}
            fetchPrograms={fetchActivePrograms}
          />
          :
          active.hasMore === false ?
            (filtersNumber > 0 || active.search) ? (
              <NoPrograms
                title='No Programs Found'
                subtitle='There are no programs that match the search you made, clear all filters and try again.'/>
            ) : (
              <NoPrograms
                title='No Active Programs Available'
                subtitle='Create a Program and make it live'/>
            ) : (
              <Loader message='loading programs' />
            )
      }
    </>
  )
}

export default CoachProgramsActiveTab
