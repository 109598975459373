import * as constants from "./actionTypes";
import * as common from "./commonActions";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { deserialize } from '../utility/serialization'
import Axios from '../axios'

export function successRequestingApproveEnrollment(response) {
  if (response.status === 200) {
    return {
      type: constants.APPROVE_ENROLLMENT,
      enrollment: response.data.enrollment,
      course: response.data.course
    };
  } else {
    console.log("ERROR ON GETTING USER ACTIVITIES");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingDenyEnrollment(
  response,
  courseId,
  enrollmentId
) {
  if (response.status === 204) {
    return {
      type: constants.DENY_ENROLLMENT,
      courseId: courseId,
      enrollmentId: enrollmentId
    };
  } else {
    console.log("ERROR ON GETTING USER ACTIVITIES");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingUpdateCourse(response, course) {
  if (response.status === 200) {
    return { type: constants.UPDATE_COURSE, course: response.data.course };
  } else {
    console.log("ERROR ON UPDATING COURSE");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingUpdateCourseAttributes(response) {
  if (response.status === 200) {
    return { type: constants.UPDATE_COURSE_ATTRIBUTES, course: response.data.course };
  } else {
    console.log("ERROR ON UPDATING COURSE");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingGetCourse(response) {
  if (response.status === 200) {
    return { type: constants.GET_COURSE, course: response.data.course };
  } else {
    console.log("ERROR ON GETTING COURSE");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingGetCourseStudents(response, course) {
  if (response.status === 200) {
    return {
      type: constants.GET_COURSE_STUDENTS,
      course: course,
      students: response.data.students
    };
  } else {
    console.log("ERROR ON GETTING COURSE STUDENTS");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingCourseSessions(response, course) {
  if (response.status === 200) {
    return {
      type: constants.GET_COURSE_SESSIONS,
      sessions: response.data.sessions
    };
  } else {
    console.log("ERROR ON GETTING COURSE SESSIONS");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingCreateCourse(response, course) {
  if (response.status === 201) {
    return { type: constants.CREATE_COURSE, course: response.data.course };
  } else {
    console.log("ERROR ON CREATING COURSE");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingCommunityCourses(response) {
  if (response.status === 200) {
    return { type: constants.ADD_COURSES, courses: response.data.courses };
  } else {
    console.log("ERROR ON GETTING COURSES");
    return { type: constants.UI_ERROR, error: response.data };
  }
}
export function successDeletingCourse(response) {
  if (response.status === 200) {
    return { type: constants.DELETE_COURSES, courses: response.data.courses };
  } else {
    console.log("ERROR ON GETTING COURSES");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// THUNKS HERE
export function approveEnrollment(enrollmentId) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestApproveEnrollment(enrollmentId)
      .then(response => dispatch(successRequestingApproveEnrollment(response)))
      .then(response => dispatch(common.loadingChanged(false)));
  };
}

export function getCommunityCourses(community_id) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestCommunityCourses(community_id)
      .then(response => dispatch(successRequestingCommunityCourses(response)))
      .then(repsonse => dispatch(common.loadingChanged(false)));
  };
}

export function denyEnrollment(courseId, enrollmentId) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestDenyEnrollment(enrollmentId)
      .then(response =>
        dispatch(
          successRequestingDenyEnrollment(response, courseId, enrollmentId)
        )
      )
      .then(response => dispatch(common.loadingChanged(false)));
  };
}

export function getCourseStudents(courseId, rankId, objectiveId) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestCourseStudents(courseId, rankId, objectiveId)
      .then(response =>
        dispatch(
          successRequestingGetCourseStudents(
            response,
            courseId,
            rankId,
            objectiveId
          )
        )
      )
      .then(response => dispatch(common.loadingChanged(false)));
  };
}

export function getCourse(course) {
  return function(dispatch) {
    dispatch(showLoading())
    return requestGetCourse(course)
      .then(response => {
        dispatch(successRequestingGetCourse(response))
        dispatch(hideLoading())
        return response.data
      })
  }
}

export function getEventCourse(course) {
  return requestGetCourse(course, '9-hole-events-roster').then((res) => deserialize(res))
}

export function updateCourse(course) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestUpdateCourse(course)
      .then(response => response.data)
    //   .then( response => dispatch(successRequestingUpdateCourse(response, course)))
    //   .then( response => dispatch(common.loadingChanged(false))
    // )
  };
}

export function updateCourseDuration(courseId) {
  return function(dispatch) {
    dispatch(showLoading())
    return requestGetCourse(courseId)
      .then(response => {
        dispatch(successRequestingUpdateCourseAttributes(response))
        dispatch(hideLoading())
        return response.data
      })
  }
}

export function createCourse(course) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true))
    return requestCreateCourse(course)
      .then(response => {
        dispatch(successRequestingCreateCourse(response, course))
        dispatch(common.loadingChanged(false))
        return response.data
      })
  }
}

export function createCourseFromTemplate(template_id, course) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true))
    return requestCreateCourseFromTemplate(template_id, course)
      .then(response => {
        dispatch(successRequestingCreateCourse(response, template_id))
        dispatch(common.loadingChanged(false))
        return response.data
      })
  }
}

export function createCourseFromProgram(course_id, course) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true))
    return requestCreateCourseFromProgram(course_id, course)
      .then(response => {
        dispatch(successRequestingCreateCourse(response, course_id))
        dispatch(common.loadingChanged(false))
        return response.data
      })
  }
}

export function deleteCourse(courseId) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestDeleteCourse(courseId);
    // .then(
    //   response => dispatch(successDeletingCourse(response))
    // ).then(
    //   response => dispatch(common.loadingChanged(false))
    // )
  };
}

export function getCourseSessions(courseId) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestGetCourseSessions(courseId)
      .then(response => dispatch(successRequestingCourseSessions(response)))
      .then(repsonse => dispatch(common.loadingChanged(false)));
  };
}

// API CALLS
function requestApproveEnrollment(enrollmentId) {
  let requestUrl = "/api/v1/enrollments/" + enrollmentId;
  return Axios.put(requestUrl, {
    status: "active"
  });
}

// API CALLS
function requestDenyEnrollment(enrollmentId) {
  let requestUrl = "/api/v1/enrollments/" + enrollmentId;
  return Axios.delete(requestUrl);
}

// API CALLS
function requestUpdateCourse(course) {
  let requestUrl = "/api/v1/courses/" + course.id;
  const data = new FormData();
  for (const key in course) {
    if (
      key === "community_id" ||
      (course.hasOwnProperty(key) && course[key] !== "")
    ) {
      data.append(key, course[key]);
    }
  }
  return Axios.put(requestUrl, data);
  // return Axios.put(requestUrl, {
  //   name: course.name,
  //   description: course.description,
  //   meeting_times: course.meeting_times,
  //   start_date: course.start_date,
  //   end_date: course.end_date,
  //   is_draft: course.is_draft,
  //   is_private: course.is_private,
  //   group_id: course.group_id,
  //   is_completed: course.is_completed,
  //   archived: course.archived,
  //   golfer_limit: course.golfer_limit
  // })
}

// API CALLS
function requestCreateCourse(course) {
  let requestUrl = "/api/v1/courses/";

  const data = new FormData();
  for (const key in course) {
    if (
      key === "community_id" ||
      (course.hasOwnProperty(key) && course[key] !== "")
    ) {
      data.append(key, course[key]);
    }
  }
  return Axios.post(requestUrl, data);
}

function requestCreateCourseFromTemplate(template_id, course) {
  let requestUrl = "/api/v1/courses/create_from_template";
  return Axios.post(requestUrl, {
    template_id: template_id,
    community_id: course.community_id,
    is_draft: course.is_draft,
    is_template: course.is_template,
    is_default_template: course.is_default_template
  });
}

function requestCreateCourseFromProgram(course_id, course) {
  let requestUrl = "/api/v1/courses/create_from_program";
  return Axios.post(requestUrl, {
    course_id: course_id,
    is_draft: course.is_draft
  });
}

function requestCommunityCourses(course_id) {
  let requestUrl = "/api/v1/communities/" + course_id + "/courses";
  return Axios.get(requestUrl);
}

function requestDeleteCourse(course_id) {
  let requestUrl = "/api/v1/courses/" + course_id;
  return Axios.delete(requestUrl);
}

function requestGetCourse(course_id, mode) {
  let requestUrl = '/api/v1/courses/' + course_id
  let params = { mode }

  return Axios.get(requestUrl, { params: params } )
}

function requestCourseStudents(course_id, rankId, objectiveId) {
  let requestUrl =
    "/api/v1/courses/" +
    course_id +
    "/course_students" +
    "?rank=" +
    rankId +
    "&objective=" +
    objectiveId;
  return Axios.get(requestUrl);
}

function requestGetCourseSessions(course_id) {
  let requestUrl = "/api/v1/courses/" + course_id + "/sessions";
  return Axios.get(requestUrl);
}
