import * as constants from './actionTypes'
import { serialize, deserialize } from "../utility/serialization"
import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleAPIError } from '../utility/actionHelper'
import Axios from '../axios'

export const getScheduleSessionsByMonth = createAsyncThunk(
  'scheduleSessions/getScheduleSessionsByMonth',
  async ({ selectedMonthDate }, { rejectWithValue }) => {
    try {
      const { data } = await requestGetScheduleSessionsByMonth(selectedMonthDate)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export function successRequestingCreateScheduleSession(response) {
  if (response.status === 201) {
    return {
      type: constants.CREATE_SCHEDULE_SESSION,
      scheduleSession: deserialize(response.data.schedule_session)
    }
  } else {
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRequestingUpdateScheduleSession(response) {
  if (response.status === 200) {
    return {
      type: constants.UPDATE_SCHEDULE_SESSION,
      scheduleSession: deserialize(response.data.schedule_session)
    }
  } else {
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successDeletingScheduleSession(response) {
  if (response.status === 200) {
    return {
      type: constants.DELETE_SCHEDULE_SESSION,
      scheduleSession: deserialize(response.data.schedule_session)
    }
  } else {
    return { type: constants.UI_ERROR, error: response.data }
  }
}

// THUNKS

export function createScheduleSession(scheduleSession) {
  return function(dispatch) {
    return requestCreateScheduleSession(scheduleSession)
      .then(response => dispatch(successRequestingCreateScheduleSession(response)))
      .then(response => deserialize(response))
  }
}

export function updateScheduleSession(scheduleSession) {
  return function(dispatch) {
    return requestUpdateScheduleSession(scheduleSession)
      .then(response => dispatch(successRequestingUpdateScheduleSession(response)))
      .then(response => deserialize(response))
  }
}

export function deleteScheduleSession(scheduleSessionId) {
  return function(dispatch) {
    return requestDeleteScheduleSession(scheduleSessionId)
      .then(response => dispatch(successDeletingScheduleSession(response)))
      .then(response => deserialize(response))
  }
}

// API CALLS

const requestGetScheduleSessionsByMonth = (monthDate) => {
  const requestUrl = '/api/v1/schedule_sessions/dashboard_calendar'

  return Axios.get(requestUrl, { params: serialize({ monthDate: monthDate.toDateString() }) })
}

function requestCreateScheduleSession(scheduleSession) {
  const requestUrl = '/api/v1/schedule_sessions'

  const data = {
    schedule_id: scheduleSession.schedule_id,
    title: scheduleSession.title,
    location: scheduleSession.location,
    description: scheduleSession.description,
    start_date: scheduleSession.startDate,
    end_date: scheduleSession.endDate
  }

  return Axios.post(requestUrl, data)
}

function requestUpdateScheduleSession(scheduleSession) {
  const requestUrl = '/api/v1/schedule_sessions/' + scheduleSession.id

  const data = {
    title: scheduleSession.title,
    location: scheduleSession.location,
    description: scheduleSession.description,
    start_date: scheduleSession.startDate,
    end_date: scheduleSession.endDate
  }

  return Axios.put(requestUrl, data)
}

function requestDeleteScheduleSession(scheduleSession_id) {
  let requestUrl = '/api/v1/schedule_sessions/' + scheduleSession_id
  return Axios.delete(requestUrl)
}
