import { createSlice, createSelector } from '@reduxjs/toolkit'
import { getLessonPlans } from '../actions/lessonPlanActions'
import { getCommunityCategories } from '../actions/programCategoryActions'
import { getProgram, getProgramsScheduleSummary, createProgram, updateProgram } from '../actions/programActions'
import { getProgramSchedule, createProgramSchedule, updateProgramSchedule } from '../actions/scheduleActions'
import { getSearchTags } from '../actions/searchTagActions'
import { getCommunityPackages } from '../actions/packagesActions'
import { getCommunityMaterials } from '../actions/materialsActions'
import { getCommunityCoaches } from '../actions/coachesActions'
import moment from 'moment'

const initialState = {
  step: 1,
  errors: {},
  isDraft: false,
  id: null,
  name: '',
  subtitle: '',
  enableProgramCapacity: false,
  spotsAvailable: '',
  visibility: 'public',
  startDate: '',
  endDate: '',
  lessonPlan: null,
  communityLessonPlans: [],
  category: null,
  communityCategories: [],
  enableRegistrationDates: false,
  registrationStartsAt: '',
  registrationEndsAt: '',
  schedule: { scheduleSessions: [], sessionIndex: 0 },
  communityPrograms: [],
  communityProgramsPagination: null,
  headerImage: null,
  headerImageUrl: '',
  personalizedVideoUrl: '',
  description: '',
  searchTags: [],
  availableSearchTags: [],
  packages: [],
  communityPackages: [],
  paymentMethod: { cash: false, card: false },
  materials: [],
  communityMaterials: [],
  coaches: [],
  communityCoaches: [],
  enrollments_count: 0,
}

const programBuilderReducer = createSlice({
  name: 'programBuilder',
  initialState,
  reducers: {
    resetBuilder: () => initialState,
    setField: (state, action) => {
      const { field, value } = action.payload

      state[field] = value
    },
    addSession: (state, action) => {
      const newSession = action.payload
      state.schedule.scheduleSessions.push({ index: state.schedule.sessionIndex, ...newSession })
      state.schedule.scheduleSessions.sort((a, b) => a.startDate > b.startDate ? 1 : -1)
      state.schedule.sessionIndex += 1
    },
    updateSession: (state, action) => {
      const session = action.payload
      const index = state.schedule.scheduleSessions.findIndex(s => s.index === session.index)

      state.schedule.scheduleSessions.splice(index, 1)
      state.schedule.scheduleSessions.push({ index: state.schedule.sessionIndex, ...session })
      state.schedule.scheduleSessions.sort((a, b) => a.startDate > b.startDate ? 1 : -1)
      state.schedule.sessionIndex += 1
    },
    deleteSession: (state, action) => {
      const session = action.payload
      const index = state.schedule.scheduleSessions.findIndex(s => s.index === session.index)

      if (state.schedule.scheduleSessions[index].id) {
        state.schedule.scheduleSessions[index].deleted = true
      } else {
        state.schedule.scheduleSessions.splice(index, 1)
      }
    },
    deleteAllSessions: (state, _) => {
      const newSessions = []
      state.schedule.scheduleSessions.forEach((s) => {
        if (s.id) {
          newSessions.push({ ...s, deleted: true })
        }
      })
      state.schedule.scheduleSessions = newSessions
    },
    addRecurringSessions: (state, action) => {
      const { startDate, endDate, repeatsEvery, numberOfSessions, repeatsOn } = action.payload
      const repeatsOnDays = Object.keys(repeatsOn).filter(key => repeatsOn[key]).map(day => parseInt(day))

      let currentDate = startDate
      let indexDay = 0 // Initialized as 0 for a Corner Case (E.g. repeatsOnDays = [2, 4], currentDate.isoWeekday() = 7 => day = 2)

      // Find the index of the next day from the repeatsOnDays array (E.g. repeatsOnDays = [3, 5, 7], currentDate.isoWeekday() = 4 => day = 5)
      repeatsOnDays.sort().some((day, index) => {
        if (day >= currentDate.isoWeekday()) {
          indexDay = index
          return true
        }
      });

      [...Array(parseInt(numberOfSessions)).keys()].forEach((index) => {
        // Go to the next date of the current week if available, if not go repeatsEvery weeks in the future
        currentDate = ((currentDate.isoWeekday() < repeatsOnDays[indexDay] || (currentDate.isoWeekday() === repeatsOnDays[indexDay] && index === 0))
          ? currentDate
          : currentDate.add(repeatsEvery, 'weeks')
        ).isoWeekday(repeatsOnDays[indexDay])
        indexDay = (indexDay + 1) % repeatsOnDays.length

        let sessionEndDate = currentDate.clone().set({ hour: endDate.hour(), minute: endDate.minute() })
        if (currentDate.toDate() >= sessionEndDate.toDate()) {
          sessionEndDate = sessionEndDate.add(1, 'days').toDate()
        }

        state.schedule.scheduleSessions.push({
          index: index + state.schedule.sessionIndex,
          title: `Session ${index + 1}`,
          startDate: currentDate.toDate(),
          endDate: sessionEndDate,
          location: '',
          description: '',
        })
      })

      state.schedule.sessionIndex = state.schedule.scheduleSessions.length
    },
    copyScheduleFromProgram: (state, action) => {
      const scheduleSessions = action.payload.scheduleSessions

      // eslint-disable-next-line no-unused-vars
      state.schedule.scheduleSessions = [...state.schedule.scheduleSessions, ...scheduleSessions.map(({ id, createdAt, updatedAt, ...s }, index) => (
        {
          ...s,
          isInvalid: true,
          index: index + state.schedule.sessionIndex,
          startDate: moment(s.startDate).toDate(),
          endDate: moment(s.endDate).toDate(),
        }
      ))]

      state.schedule.sessionIndex = state.schedule.scheduleSessions.length
    },
    addPackage: (state, action) => {
      const pkg = action.payload
      state.packages.push(pkg)
    },
    deletePackage: (state, action) => {
      const pkg = action.payload
      const index = state.packages.findIndex(p => p.id === pkg.id)

      state.packages.splice(index, 1)
    },
    addMaterial: (state, action) => {
      const material = action.payload
      state.materials.push(material)
    },
    deleteMaterial: (state, action) => {
      const material = action.payload
      const index = state.materials.findIndex(p => p.id === material.id)

      state.materials.splice(index, 1)
    },
    addCoach: (state, action) => {
      const coach = action.payload
      state.coaches.push(coach)
    },
    deleteCoach: (state, action) => {
      const coach = action.payload
      const index = state.coaches.findIndex(c => c.id === coach.id)

      state.coaches.splice(index, 1)
    },
  },
  extraReducers: {
    // Get the community lesson plans
    [getLessonPlans.fulfilled]: (state, action) => {
      state.communityLessonPlans = action.payload.communityLessonPlans
    },
    [getLessonPlans.rejected]: (state, action) => {
      state.errors = action.payload
    },

    // Get the community categories
    [getCommunityCategories.fulfilled]: (state, action) => {
      state.communityCategories = action.payload.courseCategories.filter(c => c.name !== 'Other Programs')
    },
    [getCommunityCategories.rejected]: (state, action) => {
      state.errors = action.payload
    },

    // Get the available search tags
    [getSearchTags.fulfilled]: (state, action) => {
      state.availableSearchTags = action.payload.searchTags.map(e => ({ ...e, value: e.key }))
    },
    [getSearchTags.rejected]: (state, action) => {
      state.errors = action.payload
    },

    // Get the community packages
    [getCommunityPackages.fulfilled]: (state, action) => {
      state.communityPackages = action.payload.packages
    },
    [getCommunityPackages.rejected]: (state, action) => {
      state.errors = action.payload
    },

    // Get the community materials
    [getCommunityMaterials.fulfilled]: (state, action) => {
      state.communityMaterials = action.payload.materials
    },
    [getCommunityMaterials.rejected]: (state, action) => {
      state.errors = action.payload
    },

    // Get the community coaches
    [getCommunityCoaches.fulfilled]: (state, action) => {
      state.communityCoaches = action.payload.coaches
    },
    [getCommunityCoaches.rejected]: (state, action) => {
      state.errors = action.payload
    },

    // Get program
    [getProgram.fulfilled]: (state, action) => {
      const program = action.payload.course

      state.isDraft = program.isDraft
      state.id = program.id
      state.name = program.name || ''
      state.subtitle = program.meetingTimes || ''
      state.visibility = program.visibility
      state.enableProgramCapacity = !!program.golferLimit
      state.spotsAvailable = program.golferLimit || ''
      state.startDate = program.startDate ? moment(program.startDate).toDate() : ''
      state.endDate = program.endDate ? moment(program.endDate).toDate() : ''
      state.registrationStartsAt = program.registrationStartsAt ? moment(program.registrationStartsAt).toDate() : ''
      state.registrationEndsAt = program.registrationEndsAt ? moment(program.registrationEndsAt).toDate() : ''
      state.enableRegistrationDates = !!program.registrationStartsAt || !!program.registrationEndsAt
      state.lessonPlan = program.lessonPlan || null
      state.category = program.courseCategory || null
      state.headerImageUrl = program.headerImage?.originalFileUrl || null
      state.personalizedVideoUrl = program.personalizedVideoUrl || ''
      // state.description = program.description || {}
      state.description = program.description || ''
      state.paymentMethod = program.paymentMethod || { cash: false, card: false }

      state.searchTags = []
      program.searchTags?.forEach((st) => {
        state.searchTags.push({ key: st.key, name: st.name, category: st.category, category_index: st.category_index, value: st.key })
      })

      state.packages = []
      program.availablePackages?.forEach((p) => {
        state.packages.push({ id: p.id, name: p.name, price: p.price, description: p.description })
      })

      state.materials = []
      program.materials?.forEach((m) => {
        state.materials.push({ id: m.id, name: m.name, description: m.description, photo: m.photo })
      })

      state.coaches = []
      program.coaches?.forEach((c) => {
        state.coaches.push({ id: c.id, firstName: c.firstName, lastName: c.lastName, email: c.email, avatar: c.avatar })
      })

      state.enrollments_count = program.enrollments?.length || 0
    },
    [getProgram.rejected]: (state, action) => {
      state.errors = action.payload
    },

    // Get programs
    [getProgramsScheduleSummary.fulfilled]: (state, action) => {
      state.communityPrograms = action.payload.courses
      state.communityProgramsPagination = action.payload.meta.pagination
    },
    [getProgramsScheduleSummary.rejected]: (state, action) => {
      state.errors = action.payload
    },

    // Get program schedule
    [getProgramSchedule.fulfilled]: (state, action) => {
      const schedule = action.payload.schedule

      if (!schedule) {
        return
      }

      state.schedule = { id: schedule.id }
      state.schedule.sessionIndex = schedule.scheduleSessions.length
      state.schedule.scheduleSessions = schedule.scheduleSessions.map((s, index) => ({
        index,
        id: s.id,
        title: s.title,
        startDate: moment(s.startDate).toDate(),
        endDate: moment(s.endDate).toDate(),
        location: s.location,
        description: s.description,
        isInvalid: s.isInvalid,
      }))
      state.schedule.scheduleSessions.sort((a, b) => a.startDate > b.startDate ? 1 : -1)
    },
    [getProgramSchedule.rejected]: (state, action) => {
      state.errors = action.payload
    },

    // Create program
    [createProgram.rejected]: (state, action) => {
      state.errors = action.payload

      if (state.errors.coachesIds) {
        state.errors.step1 = true

        state.coaches = []
        state.step = 1
      }

      if (state.errors.searchTagsKeys) {
        state.errors.step3 = true

        state.searchTags = []
        state.step = 3
      }

      if (state.errors.packagesIds) {
        state.errors.step4 = true

        state.packages = []
        state.step = 4
      }

      if (state.errors.materialsIds) {
        state.errors.step4 = true

        state.materials = []
        state.step = 4
      }

      if (state.errors.lessonPlan) {
        state.errors.step1 = true

        state.lessonPlan = null
        state.step = 1
      }

      if (state.errors.courseCategoryId) {
        state.errors.step3 = true

        state.category = null
        state.step = 3
      }
    },

    // Create program schedule
    [createProgramSchedule.rejected]: (state, action) => {
      state.errors = action.payload
    },

    // Update program
    [updateProgram.rejected]: (state, action) => {
      state.errors = action.payload

      if (state.errors.coachesIds) {
        state.errors.step1 = true

        state.coaches = []
        state.step = 1
      }

      if (state.errors.packagesIds) {
        state.errors.step4 = true

        state.packages = []
        state.step = 4
      }

      if (state.errors.searchTagsKeys) {
        state.errors.step3 = true

        state.searchTags = []
        state.step = 3
      }

      if (state.errors.materialsIds) {
        state.errors.step4 = true

        state.materials = []
        state.step = 4
      }

      if (state.errors.lessonPlan) {
        state.errors.step1 = true

        state.lessonPlan = null
        state.step = 1
      }

      if (state.errors.courseCategoryId) {
        state.errors.step3 = true

        state.category = null
        state.step = 3
      }
    },

    // Update program schedule
    [updateProgramSchedule.rejected]: (state, action) => {
      state.errors = action.payload
    },
  },
})

export const {
  resetBuilder,
  setField,
  addSession,
  updateSession,
  deleteSession,
  deleteAllSessions,
  addRecurringSessions,
  copyScheduleFromProgram,
  addPackage,
  deletePackage,
  addMaterial,
  deleteMaterial,
  addCoach,
  deleteCoach,
} = programBuilderReducer.actions

export const scheduleDates = createSelector(
  state => state.programBuilder.schedule.scheduleSessions,
  scheduleSessions => {
    const sessions = scheduleSessions.filter(s => !s.deleted)

    if (sessions.length === 0) {
      return { scheduleStartDate: '', scheduleEndDate: '' }
    }

    sessions.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)))
    return { scheduleStartDate: sessions[0].startDate, scheduleEndDate: sessions[sessions.length - 1].startDate }
  }
)

export const programFormData = createSelector(
  state => state.programBuilder,
  programFields => ({
    name: programFields.name,
    meetingTimes: programFields.subtitle,
    visibility: programFields.visibility,
    golferLimit: programFields.enableProgramCapacity ? programFields.spotsAvailable : '',
    startDate: programFields.startDate,
    endDate: programFields.endDate,
    lessonPlanId: programFields.lessonPlan?.id || '',
    courseCategoryId: programFields.category?.id || '',
    registrationStartsAt: programFields.enableRegistrationDates ? programFields.registrationStartsAt : '',
    registrationEndsAt: programFields.enableRegistrationDates ? programFields.registrationEndsAt : '',
    headerImage: programFields.headerImage || '',
    personalizedVideoUrl: programFields.personalizedVideoUrl || '',
    searchTagsKeys: JSON.stringify(programFields.searchTags.map(tag => tag.key)),
    description: programFields.description,
    packagesIds: JSON.stringify(programFields.packages.map(pkg => pkg.id)),
    paymentMethod: programFields.packages.length > 0 ? JSON.stringify(programFields.paymentMethod) : '',
    materialsIds: JSON.stringify(programFields.materials.map(material => material.id)),
    coachesIds: JSON.stringify(programFields.coaches.map(c => c.id)),
  })
)

export const programPresentationData = createSelector(
  state => state.programBuilder,
  programFields => ({
    previewMode: true,
    id: -1,
    name: programFields.name,
    meetingTimes: programFields.subtitle,
    visibility: programFields.visibility,
    spotsLeft: programFields.enableProgramCapacity ? programFields.spotsAvailable : '',
    startDate: programFields.startDate,
    endDate: programFields.endDate,
    lessonPlanId: programFields.lessonPlan?.id || '0',
    courseCategoryId: programFields.category?.id || '',
    registrationStartsAt: programFields.enableRegistrationDates ? programFields.registrationStartsAt : '',
    registrationEndsAt: programFields.enableRegistrationDates ? programFields.registrationEndsAt : '',
    headerImage: programFields.headerImageUrl ? { originalFileUrl: programFields.headerImageUrl } : '',
    personalizedVideoUrl: programFields.personalizedVideoUrl || '',
    searchTags: programFields.searchTags.map(tag => tag),
    description: programFields.description,
    packages: programFields.packages,
    paymentMethod: programFields.packages.length > 0 ? programFields.paymentMethod : { cash: true },
    materials: programFields.materials,
    schedule: programFields.schedule,
  })
)

export const programPresentationTabs = createSelector(
  state => state.programBuilder,
  programFields => {
    const detailsTabs = ['Description']

    if (programFields.schedule.scheduleSessions.filter(s => !s.deleted).length > 0) {
      detailsTabs.push('Schedule')
    }
    if (programFields.materials.length + programFields.packages.length > 0) {
      detailsTabs.push('Pricing')
    }

    return detailsTabs
  }
)

export default programBuilderReducer.reducer
