import { useDispatch, useSelector } from 'react-redux'

import CoachProgramsFilterBar from './CoachProgramsFilterBar'
import { getCoachPrograms } from '../../../actions/programActions'
import { perPage, selectInitialFilters } from '../../../reducers/groupProgramsReducer'
import CoachProgramsList from './CoachProgramsList'
import { selectFilteredCategory, selectFiltersNumber } from '../../../reducers/groupProgramsReducer'
import NoPrograms from './EmptyProgramsList'
import Loader from '../../common/Op36Loader-web'

const CoachProgramsCompletedTab = () => {
  const dispatch = useDispatch()
  const completed = useSelector(state => state.groupPrograms.completed)
  const initialFilters = useSelector(selectInitialFilters)
  const filtersNumber = useSelector(state => selectFiltersNumber(state, completed.filters))
  const filteredCategory = useSelector(state => selectFilteredCategory(state, completed.filters?.categoryId))
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)

  const fetchCompletedPrograms = () => {
    dispatch(getCoachPrograms({
      filters: { ...completed.filters, completed: 'true', search: completed.search },
      page: completed.page + 1,
      perPage: perPage,
      withEnrollments: 'true',
    }))
  }

  const handleReloadPrograms = ({ filters, search }) => {
    if (filters) {
      const requestFilters = {
        categoryId: filters.categoryId,
        paymentMethod: filters.paymentMethod,
        visibility: filters.visibility,
        startDate: filters.startDate,
        endDate: filters.endDate,
      }

      dispatch(getCoachPrograms({
        filters: { ...requestFilters, completed: 'true', search: completed.search },
        page: 1,
        perPage: perPage,
        withEnrollments: 'true',
      }))
    } else if (search !== undefined) {
      dispatch(getCoachPrograms({
        filters: { ...completed.filters, completed: 'true', search: search },
        page: 1,
        perPage: perPage,
        withEnrollments: 'true',
      }))
    } else {
      dispatch(getCoachPrograms({
        filters: { ...initialFilters, completed: 'true', search: completed.search },
        page: 1,
        perPage: perPage,
        withEnrollments: 'true',
      }))
    }
  }

  return (
    <>
      <CoachProgramsFilterBar
        filters={completed.filters}
        filteredCategory={filteredCategory}
        filtersNumber={filtersNumber}
        handleReloadPrograms={handleReloadPrograms}
        search={completed.search}
      />
      {
        completed.programs.length > 0 ?
          <CoachProgramsList
            programs={completed.programs}
            timezone={timezone}
            hasMore={completed.hasMore}
            fetchPrograms={fetchCompletedPrograms}
          />
          :
          completed.hasMore === false ?
            (filtersNumber > 0 || completed.search) ? (
              <NoPrograms
                title='No Programs Found'
                subtitle='There are no programs that match the search you made, clear all filters and try again.'/>
            ) : (
              <NoPrograms
                title='No Completed Programs Available'
                subtitle='Create a Program and make it live'/>
            ) : (
              <Loader message='loading programs' />
            )
      }
    </>
  )
}

export default CoachProgramsCompletedTab
