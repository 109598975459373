import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

import { ReactComponent as EditIcon } from '../../../../assets/images/common/icons/programBuilder/edit-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/images/common/icons/programBuilder/delete-icon.svg'
import colors from '../../../../assets/styles/globals.scss'
import './SessionCard.scss'

const SessionCard = ({ session, handleEditSession, handleDeleteSession }) => {
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)

  const formatTimeRange = (start, end) => `${moment(start).tz(timezone).format('hh:mm a')} - ${moment(end).tz(timezone).format('hh:mm a')}`

  return (
    <div className='d-flex justify-content-center schedule-builder-session-card' key={session.index}>
      <div
        className='date-info'
        aria-hidden='true'
        style={{ cursor: handleEditSession && 'pointer' }}
        onClick={() => handleEditSession && handleEditSession(session)}
      >
        {session.isInvalid ? (
          <>
            <span className='missing-date'>Add</span>
            <span className='missing-date'>Date</span>
          </>
        ) : (
          <>
            <span className='month-name'>{ moment(session.startDate).tz(timezone).format('MMM') }</span>
            <span className='day-number'>{ moment(session.startDate).tz(timezone).format('DD') }</span>
            <span>{moment(session.startDate).tz(timezone).format('ddd') }</span>
          </>
        )}
      </div>

      <div className='session-info'>
        <div className='d-flex justify-content-between w-100'>
          <p className='title'>
            { session.title }
          </p>
          <div>
            {handleEditSession && (
              <EditIcon fill={colors.primaryOrange} aria-hidden='true' class='edit-icon' onClick={() => handleEditSession(session)} />
            )}
            {handleDeleteSession && (
              <DeleteIcon aria-hidden='true' class='delete-icon ml-3' onClick={() => handleDeleteSession(session)} />
            )}
          </div>
        </div>

        <div className='d-flex align-items-center mb-2 w-100 time-range'>
          <span className='icon-wrapper'><i className='fa fa-clock-o icon'/></span>
          <p>{formatTimeRange(session.startDate, session.endDate)}</p>
        </div>

        {session?.location && (
          <div className='d-flex align-items-center mb-2 w-100'>
            <span className='icon-wrapper'><i className='fa fa-map-marker icon'/></span>
            <p>{session.location}</p>
          </div>
        )}

        {session?.description && (
          <p className='description w-100'>
            {session.description}
          </p>
        )}
      </div>
    </div>
  )
}

SessionCard.propTypes = {
  session: PropTypes.object,
  handleEditSession: PropTypes.func,
  handleDeleteSession: PropTypes.func,
}

export default SessionCard
