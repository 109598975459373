/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { withRouting } from '../../common/hooks'
import { ReactComponent as OutsideLinkIcon } from '../../../assets/images/common/icons/outside-link-icon.svg'
import PropTypes from 'prop-types'

import './ClassDetailHeader.scss'
import { connect } from 'react-redux'

class ClassDetailHeader extends React.Component {
  state = {
    buttonPopover: false,
  }


  getClassTitle(course) {
    if (course.is_draft) {
      return `${course.name || ''} : [Draft]`
    } else if (course.archived) {
      return course.name + ' : [Archived]'
    } else if (course.is_completed) {
      return course.name + ' : [Completed]'
    } else {
      return course.name
    }
  }

  getDraftStatus(course) {
    if (course.archived) {
      return 'Archived'
    } else if (course.is_completed) {
      return 'Completed'
    } else if (course.is_draft) {
      return 'Draft'
    } else {
      return 'Published'
    }
  }

  componentWillUnmount() {
    this._closeButtonPopover()
  }

  render() {
    const { course, loader, admin, navigate } = this.props
    // let isComplete = course.is_completed;

    const datesValid = course.start_date && course.end_date
    const showSignUpPage = course.visibility !== 'private' &&
      !course.is_disabled &&
      !course.is_completed &&
      !course.is_draft
    const timezone = this.props.currentUser.communities[0].timezone

    return (
      <div id='ClassDetailHeader' className=' classDetailHeader text-white'>
        <div className='class-detail-top-button-bar mx-15'>
          <div className='col-10 col-md-8 d-flex align-items-center'>
            <i
              className='fa fa-long-arrow-left'
              aria-hidden='true'
              onClick={() => navigate('/programs')}
            />
            <h4 style={{ marginBottom: 0, marginLeft: '8px' }}>
              {this.getClassTitle(course)}
            </h4>
          </div>
          <div className='col-2 col-md-4 d-flex justify-content-end'>
            {showSignUpPage && (
              <Link
                to={`/landing/${course.community_id}/class/${this.props.params.id}`}
                target='_blank'
                className='d-flex align-items-center view-sign-up-page'
              >
                <OutsideLinkIcon id='outsideLinkIcon' />
                <span className='view-sign-up-page-label'>
                  View sign-up page
                </span>
              </Link>
            )}
            <div className='class-detail-action-button-box'>
              {!loader && (
                <i
                  className={`fa fa-chevron-down ${
                    this.state.buttonPopover ? 'invert' : ''
                  }`}
                  aria-hidden='true'
                  onClick={this._openButtonPopover}
                >
                  {this.state.buttonPopover && (
                    <div
                      className='class-button-popover'
                      ref={(el) => (this.buttonPopover = el)}
                    >
                      {this._renderTopButtons(
                        course.archived,
                        admin,
                        course
                      )}
                    </div>
                  )}
                </i>
              )}
            </div>
          </div>
        </div>
        <div className='class-detail-top-button-bar'>
          <p
            className='text-center animated fadeIn mb-0'
            style={{ fontSize: '0.9rem' }}
          >
            {course.meeting_times === 'null' ? '' : course.meeting_times}
          </p>
          {datesValid && (
            <p
              className='text-center animated fadeIn mb-0'
              style={{ fontSize: '0.9rem' }}
            >
              {moment.tz(course.start_date, timezone).format('MM/DD/YYYY') +
                ' - ' +
                moment.tz(course.end_date, timezone).format('MM/DD/YYYY')}
            </p>
          )}
        </div>
      </div>
    )
  }

  _openButtonPopover = () => {
    document.addEventListener('click', this._checkClick, false)
    this.setState({ buttonPopover: true })
  }

  _closeButtonPopover = () => {
    document.removeEventListener('click', this._checkClick, false)
    this.setState({ buttonPopover: false })
  }

  _checkClick = (e) => {
    if (this.buttonPopover && !this.buttonPopover.contains(e.target)) {
      this._closeButtonPopover()
    }
  }

  _renderTopButtons = (archived, admin, course) => {
    const btnArray = []
    btnArray.push(
      <p
        key='2'
        // className='btn mx-2 my-1 btn-primary text-white'
        // disabled={archived}
        style={archived ? { cursor: 'not-allowed' } : null}
        aria-hidden='true'
        onClick={() => this.props.navigate(`/programs/${course.id}/edit`)}
      >
        {/* <i className='fa fa-pencil' aria-hidden='true' />  */}
        Edit <span className='d-none d-md-inline'>Program</span>
      </p>
    )
    btnArray.push(
      <p
        key='3'
        // className='btn mx-2 my-1 btn-primary text-white'
        // disabled={archived}
        style={archived ? { cursor: 'not-allowed' } : null}
        aria-hidden='true'
        onClick={() => this.props.navigate(`/programs/${course.id}/edit?step=2`)}>
        {/* <i className='fa fa-pencil' aria-hidden='true' />  */}
        Edit <span className='d-none d-md-inline'>Schedule</span>
      </p>
    )
    if (admin) {
      btnArray.push(
        <p
          key='4'
          // className='btn mx-2 my-1 btn-danger text-white'
          onClick={course.is_draft ? null : this.props.archiveProgram}
          // disabled={course.is_draft}
          style={course.is_draft ? { cursor: 'not-allowed' } : null}
        >
          archive
        </p>
      )
    }
    if (!archived) {
      btnArray.push(
        <p
          key='6'
          className='delete'
          // className='btn mx-2 my-1 btn-warning text-white new-archive-program-button'
          onClick={() => this.props.deleteClass()}
        >
          {/* <i className='fa fa-trash' aria-hidden='true' /> */}
          Delete
        </p>
      )
    }
    return btnArray
  }
}

ClassDetailHeader.propTypes = {
  currentUser: PropTypes.shape({
    communities: PropTypes.arrayOf(
      PropTypes.shape({
        timezone: PropTypes.string,
      })
    ),
  }),
  course: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    community_id: PropTypes.number,
    is_disabled: PropTypes.bool,
    is_completed: PropTypes.bool,
    is_draft: PropTypes.bool,
    visibility: PropTypes.string,
    archived: PropTypes.bool,
    meeting_times: PropTypes.string,
  }),
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
  navigate: PropTypes.func,
  openEditClassModal: PropTypes.func,
  deleteClass: PropTypes.func,
  archiveProgram: PropTypes.func,
  loader: PropTypes.bool,
  admin: PropTypes.object,
}

function mapStateToProps(state, _ownProps) {
  return {
    currentUser: state.user.current_user,
  }
}

export default connect(mapStateToProps)(withRouting(ClassDetailHeader))
