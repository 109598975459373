import React, { Component } from "react";
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../actions/userActions";
import * as activityActions from "../../actions/activityActions";
import * as objectiveActions from "../../actions/objectiveActions";
import * as goalActions from "../../actions/goalActions";
import * as Constants from '../../misc/Constants'
import Slider from '../common/Slider'
import Modal from "react-modal";
import { Wizard, Steps, Step } from "react-albus";
import Datetime from "react-datetime";
import moment from "moment";

// Import Icons
import PlayIcon from "../../assets/images/common/icons/activityMenu/playIcon.png";
import TrainIcon from "../../assets/images/common/icons/activityMenu/trainIcon.png";
import SocialIcon from "../../assets/images/common/icons/activityMenu/socialIcon.png";
import Op36Icon from "../../assets/images/logos/OP36_clean_icon.png";

import Loader from "../common/Op36Loader-web";
import NewGoalTask from "./Goals/NewGoalTask";
import TextInput from "../common/form/TextInput";
import ImageUploader from "../common/form/ImageUploader";

//Import Styles
import "./LogActionsModal.scss";

const logActions = [
  {
    id: 1,
    title: "Play",
    subtitle: "Log A Round",
    icon: PlayIcon,
  },
  {
    id: 2,
    title: "Train",
    subtitle: "Log A Practice Session",
    icon: TrainIcon,
  },
  {
    id: 3,
    title: "Goals",
    subtitle: "Add Private Goal",
    icon: SocialIcon,
  },
];

class LogActionsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: this.props.user ? this.props.user.id : null,
      divisions: [225, 450, 900, 1350, 1800],
      actionSelected: -1,
      activityId: "",
      date: "",
      format: 1,
      holes: 0,
      yardage: 0,
      location: "",
      score: "",
      greens: "",
      putts: "",
      description: "",
      trainSession: {},
      issueTrophyFormUserId: this.props.user ? this.props.user.id : -1,
      issueTrophyFormObjectiveId: -1,
      currentEnrollment: {},
      isRankCompleted: false,
      putting: 0,
      chipping: 0,
      pitchShots: 0,
      wedges: 0,
      irons: 0,
      woods: 0,
      simulation: 0,
      bunkers: 0,
      goalName: "",
      goalVideo: "",
      goalDescription: "",
      goalSubjectId: 0,
      goalTaskArray: [0],
      goalTasks: [],
      goalLoader: false,
      submitClicked: false,
      goalImage: "",
      uploadPhotoFileSrc: "",
      uploadPhotoName: "",
      uploadPhotoFile: "",
      didUploadPhoto: false,
      uploadingPhoto: false,
      pictures: [],
      activityDate: moment().format(),
      shareScore: true,
    };
  }

  handleActionSelect = (id, next) => {
    this.resetForms();
    this.setState(
      {
        actionSelected: id,
      },
      () => {
        if (next) {
          next();
        }
      }
    );
  };

  handleShareScoreChange = (event) => {
    this.setState((prevState) => {
      return { shareScore: !prevState.shareScore };
    });
  };

  activityDateChanged = (event) => {
    let startDate = moment(event, "MM-DD-YYYY").format("MM-DD-YYYY");
    let setActivityDate = moment(startDate, "MM-DD-YYYY").format();

    this.setState({
      activityDate: setActivityDate,
    });
  };

  logActionsWelcome = (next) => {
    const { showCreateGoal, current_user } = this.props;
    if (showCreateGoal) next();
    return logActions.map((action) => {
      if (action.id === 3 && current_user.type === "Student") {
        return null;
      }
      if (action.id === 3 && current_user.user_settings.coach_permission < 2) {
        return null;
      }
      return (
        <div
          className={`${
            current_user.type === "Student" ||
            (current_user.user_settings.coach_permission < 2 &&
              current_user.type === "Coach")
              ? "col-md-6"
              : "col-md-4"
          }`}
          key={action.id}
        >
          <div
            className={`card log-action-card ${
              this.state.actionSelected === action.id
                ? "log-action-card-active"
                : ""
            }`}
            onClick={() => this.handleActionSelect(action.id, next)}
          >
            <img
              src={action.icon}
              alt={action.title}
              className='log-action-image'
            />
            <h5 className='log-action-title'>{action.title}</h5>
            <h5 className='log-action-subtitle'>{action.subtitle}</h5>
          </div>
        </div>
      );
    });
  };

  // Handle Form Logic

  resetForms = () => {
    this.setState({
      actionSelected: -1,
      activityId: "",
      date: "",
      format: 1,
      holes: 0,
      yardage: 0,
      fullTeeYardage: 0,
      location: "",
      score: "",
      greens: "",
      putts: "",
      trainSession: [],
      description: "",
      issueTrophyFormUserId: this.props.user ? this.props.user.id : -1,
      userId: this.props.user ? this.props.user.id : null,
      issueTrophyFormObjectiveId: -1,
      currentEnrollment: {},
      putting: 0,
      chipping: 0,
      pitchShots: 0,
      wedges: 0,
      irons: 0,
      woods: 0,
      simulation: 0,
      bunkers: 0,
      goalName: "",
      goalVideo: "",
      goalDescription: "",
      goalSubjectId: 0,
      goalTaskArray: [0],
      goalTasks: [],
      submitClicked: false,
      goalCompletion: 0,
      goalImage: "",
      uploadPhotoFileSrc: "",
      uploadPhotoFileSources: [],
      uploadPhotoName: "",
      uploadPhotoFile: "",
      didUploadPhoto: false,
      uploadingPhoto: false,
      pictures: [],
      activityDate: moment().format(),
      shareScore: true,
    });
  };

  resetSliders = () => {
    this.setState({
      putting: 0,
      chipping: 0,
      pitchShots: 0,
      wedges: 0,
      irons: 0,
      woods: 0,
      simulation: 0,
      bunkers: 0,
    });
  };

  postPlay = () => {
    this.props.setActivityAdded(true)
    this.props.activity_actions
      .coachPostActivityPlay(this.state)
      .then((response) => {
        if (response) {
          this.props.actions
            .getUserPlayActivities(this.props.user.id)
            .then(() => {
              this.props.actions.getUserStats(this.props.user.id);
              this.props.actions.getUserActivities(this.props.user.id)
              this.props.closeLogActionsModalandUpdate();
            });
        }
        this.resetForms();
        this.props.setActivityAdded(false)
      });
  };

  handleLogClick = () => {
    const { showCreateGoal } = this.props;
    const { score, description, holes, yardage, fullTeeYardage } = this.state
    this.setState(
      {
        submitClicked: true,
        userId: this.props.user.id
      },
      () => { // eslint-disable-line complexity
        if (this.state.actionSelected === 1) {
          if (score !== '' && description !== '' && holes !== 0) {
            if (
              yardage === 'fullTee' &&
              parseInt(fullTeeYardage, 10) < (parseInt(holes, 10) === 9 ? 1801 : 3601)
            ) {
              this.props.toggleValidationModal(
                false,
                'Please enter a valid custom full tee box division yardage'
              )
            } else if ( !yardage || yardage === '0' ) {
              this.props.toggleValidationModal(
                false,
                'Please enter the level yardage to log a play action'
              )
            } else {
              this.postPlay()
            }
          } else {
            this.props.toggleValidationModal(
              false,
              'Please enter a score, level yardage, holes played, and brief description to log a play action'
            )
          }
        } else if (this.state.actionSelected === 2) {
          let trainSession = []
          if (this.state.putting >= 0) {
            trainSession.push({ type: 1, duration: this.state.putting });
          }
          if (this.state.chipping >= 0) {
            trainSession.push({ type: 2, duration: this.state.chipping });
          }
          if (this.state.pitchShots >= 0) {
            trainSession.push({ type: 3, duration: this.state.pitchShots });
          }
          if (this.state.wedges >= 0) {
            trainSession.push({ type: 4, duration: this.state.wedges });
          }
          if (this.state.irons >= 0) {
            trainSession.push({ type: 5, duration: this.state.irons });
          }
          if (this.state.woods >= 0) {
            trainSession.push({ type: 6, duration: this.state.woods });
          }
          if (this.state.simulation >= 0) {
            trainSession.push({ type: 7, duration: this.state.simulation });
          }
          if (this.state.bunkers >= 0) {
            trainSession.push({ type: 8, duration: this.state.bunkers });
          }

          const trainSessionDuration = trainSession.reduce((sum, trainType) => sum + trainType.duration, 0)

          if (trainSessionDuration === 0 && this.state.description === '') {
            this.props.toggleValidationModal(false, 'Please fill out the form before logging the practice.')
          } else if (trainSessionDuration === 0) {
            this.props.toggleValidationModal(false, 'The training session must take at least 1 minute. Please use a slider to track a practice.')
          } else if (this.state.description === '') {
            this.props.toggleValidationModal(false, 'Please fill out the description for the practice session.')
          } else {
            this.props.activity_actions
              .coachPostActivityTrain(this.state, trainSession)
              .then((response) => {
                if (response) {
                  this.props.actions
                    .getUserTrainActivities(this.props.user.id, this.props.trainCurrentPage, this.props.perPage)
                    .then((res) => {
                      this.props.setTrainActivitiesPages(res.pagination?.total_pages)
                      this.props.actions.getUserStats(this.props.user.id);
                      this.props.actions.getUserActivities(this.props.user.id)
                      this.props.closeLogActionsModal();
                      this.props.toggleValidationModal(
                        true,
                        `You have successfully logged a practice for ${this.props.user.first_name} ${this.props.user.last_name}`
                      );
                    });
                }
                this.resetForms();
              });
            this.resetForms();
          }
        } else if (this.state.actionSelected === 4) {
          this.props.completeObjective();
          this.resetForms();
        } else if (this.state.actionSelected === 3 || showCreateGoal) {
          this.setState({
            goalLoader: true,
          });
          this.onSubmitTask();
        }
      }
    );
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleHolesChange = (event) => {
    const { divisions, yardage } = this.state
    const newHoles = event.target.value

    const newDivisions = newHoles === '9' ? [225, 450, 900, 1350, 1800] : [450, 900, 1800, 2700, 3600]

    const newYardage = yardage * newDivisions[0] / divisions[0]

    this.setState({
      holes: newHoles,
      divisions: newDivisions,
      yardage: newYardage,
    })
  }

  onPuttingChange = (value) => {
    this.setState({
      putting: value,
    });
  };

  onChippingChange = (value) => {
    this.setState({
      chipping: value,
    });
  };

  onPitchChange = (value) => {
    this.setState({
      pitchShots: value,
    });
  };

  onWedgesChange = (value) => {
    this.setState({
      wedges: value,
    });
  };

  onIronsChange = (value) => {
    this.setState({
      irons: value,
    });
  };

  onWoodsChange = (value) => {
    this.setState({
      woods: value,
    });
  };

  onSimulationChange = (value) => {
    this.setState({
      simulation: value,
    });
  };

  onBunkersChange = (value) => {
    this.setState({
      bunkers: value,
    });
  };

  displayRankObjectives() {
    if (this.props.incompleteObjectives.length > 0) {
      return this.props.incompleteObjectives.map((objective, index) => {
        return this.displayRankObjective(objective);
      });
    }
  }

  displayRankObjective(objective) {
    if (objective.is_completed === false) {
      return (
        <option value={objective.id} key={objective.id}>
          {objective.subject.name} : {objective.name}
        </option>
      );
    }
  }

  renderPlayTypes() {
    let { playTypes, current_user } = this.props;

    playTypes = playTypes.filter((playType) => playType.id !== Constants.PLAY_TYPE.TOURNAMENT_ID)
    if (current_user?.type && current_user.type !== 'Coach') {
      playTypes = playTypes.filter((playType) => playType.id !== Constants.PLAY_TYPE.OP36_ID)
    }
    return playTypes.map((play) => {
      return (
        <option value={play.id} key={play.id}>
          {play.name}
        </option>
      );
    });
  }

  createGoalTask = () => {
    let taskArray = [...this.state.goalTaskArray];
    let lastNum = taskArray[taskArray.length - 1];

    let newNum = lastNum + 1;

    taskArray.push(newNum);

    this.setState({
      goalTaskArray: taskArray,
    });
  };

  deleteGoalTask = () => {
    let taskArray = [...this.state.goalTaskArray];
    let goalArray = [...this.state.goalTasks];
    taskArray.splice(-1, 1);
    goalArray.splice(-1, 1);
    this.setState({
      goalTaskArray: taskArray,
      goalTasks: goalArray,
    });
  };

  renderGoalTasks = () => {
    let lastTaskIndex = this.state.goalTaskArray.lastIndexOf(
      this.state.goalTaskArray[this.state.goalTaskArray.length - 1]
    );
    return this.state.goalTaskArray.map((task, index) => {
      return (
        <NewGoalTask
          sendData={(getData) => {
            this.getData = getData;
          }}
          key={index}
          index={index}
          onObjectChange={this.onObjectChange}
          lastIndex={lastTaskIndex}
        />
      );
    });
  };

  renderDivisions = () => this.state.divisions.map((div, index) => (
    <option value={div} key={index}>{`Level ${
      index + 1
    } ${div} yards`}</option>
  ))

  renderSubjects() {
    if (this.props.subjects) {
      return this.props.subjects.map((subject, index) => {
        if (subject.objectives.length > 1) {
          return (
            <option value={subject.id} key={index}>
              {subject.name}
            </option>
          );
        } else {
          return null;
        }
      });
    } else {
      return null;
    }
  }

  handleTrainTypeChange = (changeEvent) => {
    var el = changeEvent.target;
    var checked = el.checked;
    var practiceType = el.dataset.practiceType;
    var practiceTypeName = el.value;
    var updated = this.state.trainSession;

    if (checked) {
      if (window.navigator.notification) {
        window.navigator.notification.prompt(
          "Enter the amount of minutes you spent training on " +
            practiceTypeName, // message
          this.onPrompt.bind(this, practiceType), // callback to invoke
          ["Registration"], // title
          ["Ok", "Cancel"], // buttonLabels
          "60" // defaultText
        );
      } else {
        var duration = prompt(
          "Enter the amount of minutes you spent training on " +
            practiceTypeName,
          "60"
        );
        if (duration !== null) {
          updated[practiceType] = { type: practiceType, duration: duration };
        }
        this.setState({
          trainSession: updated,
        });
      }
    } else {
      delete updated[practiceType];
      this.setState({
        trainSession: updated,
      });
    }
  };

  outputDuration(trainType) {
    if (this.state.trainSession[trainType] !== undefined) {
      // We have a duration
      return (
        <span style={{ marginLeft: "5px" }}>
          ({this.state.trainSession[trainType]["duration"]} mins)
        </span>
      );
    } else {
      // We don't have a duration
      return <span />;
    }
  }

  // Render Forms
  renderTrainForm() {
    return (
      <div className='row d-flex align-items-center flex-column h-100 mt-5'>
        <div className='col-md-12 mx-auto'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='program-activity-title'>Activity Date</label>
                <Datetime
                  name='firstStartDate'
                  value={moment(this.state.activityDate).format("MM-DD-YYYY")}
                  onChange={this.activityDateChanged}
                  dateFormat='MM-DD-YYYY'
                  timeFormat={false}
                  inputProps={{ readOnly: true }}
                  className='date-time'
                  {...this.state}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group'>
                <label htmlFor='program-activity-title'>Description</label>
                <textarea
                  type='text'
                  rows={3}
                  name='description'
                  className='form-control '
                  placeholder='Train Description'
                  value={this.state.description}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-md-6'>
              <div className='train-title'>
                <p>Putting</p>
                <p className='train-duration-pill'>{this.state.putting} mins</p>
              </div>
              <Slider
                min={0}
                max={300}
                step={5}
                value={this.state.putting}
                onChange={this.onPuttingChange}
                trackStyle={{ backgroundColor: '#ff6633', height: 4 }}
                handleStyle={{
                  borderColor: '#ff6633',
                  backgroundColor: '#ff6633',
                }}
              />
            </div>
            <div className='col-md-6'>
              <div className='train-title'>
                <p>Chipping</p>
                <p className='train-duration-pill'>
                  {this.state.chipping} mins
                </p>
              </div>
              <Slider
                min={0}
                max={300}
                step={5}
                value={this.state.chipping}
                onChange={this.onChippingChange}
                trackStyle={{ backgroundColor: '#ff6633', height: 4 }}
                handleStyle={{
                  borderColor: '#ff6633',
                  backgroundColor: '#ff6633',
                }}
              />
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-md-6'>
              <div className='train-title'>
                <p>Pitch Shots (10-30 yards)</p>
                <p className='train-duration-pill'>
                  {this.state.pitchShots} mins
                </p>
              </div>
              <Slider
                min={0}
                max={300}
                step={5}
                value={this.state.pitchShots}
                onChange={this.onPitchChange}
                trackStyle={{ backgroundColor: '#ff6633', height: 4 }}
                handleStyle={{
                  borderColor: '#ff6633',
                  backgroundColor: '#ff6633',
                }}
              />
            </div>
            <div className='col-md-6'>
              <div className='train-title'>
                <p>Wedges (30+ yards)</p>
                <p className='train-duration-pill'>{this.state.wedges} mins</p>
              </div>
              <Slider
                min={0}
                max={300}
                step={5}
                value={this.state.wedges}
                onChange={this.onWedgesChange}
                trackStyle={{ backgroundColor: '#ff6633', height: 4 }}
                handleStyle={{
                  borderColor: '#ff6633',
                  backgroundColor: '#ff6633',
                }}
              />
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-md-6'>
              <div className='train-title'>
                <p>Full Swing Irons</p>
                <p className='train-duration-pill'>{this.state.irons} mins</p>
              </div>
              <Slider
                min={0}
                max={300}
                step={5}
                value={this.state.irons}
                onChange={this.onIronsChange}
                trackStyle={{ backgroundColor: '#ff6633', height: 4 }}
                handleStyle={{
                  borderColor: '#ff6633',
                  backgroundColor: '#ff6633',
                }}
              />
            </div>
            <div className='col-md-6'>
              <div className='train-title'>
                <p>Full Swing Woods</p>
                <p className='train-duration-pill'>{this.state.woods} mins</p>
              </div>
              <Slider
                min={0}
                max={300}
                step={5}
                value={this.state.woods}
                onChange={this.onWoodsChange}
                trackStyle={{ backgroundColor: '#ff6633', height: 4 }}
                handleStyle={{
                  borderColor: '#ff6633',
                  backgroundColor: '#ff6633',
                }}
              />
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-md-6'>
              <div className='train-title'>
                <p>On-Course Play Simulation</p>
                <p className='train-duration-pill'>
                  {this.state.simulation} mins
                </p>
              </div>
              <Slider
                min={0}
                max={300}
                step={5}
                value={this.state.simulation}
                onChange={this.onSimulationChange}
                trackStyle={{ backgroundColor: '#ff6633', height: 4 }}
                handleStyle={{
                  borderColor: '#ff6633',
                  backgroundColor: '#ff6633',
                }}
              />
            </div>
            <div className='col-md-6'>
              <div className='train-title'>
                <p>Bunkers</p>
                <p className='train-duration-pill'>{this.state.bunkers} mins</p>
              </div>
              <Slider
                min={0}
                max={300}
                step={5}
                value={this.state.bunkers}
                onChange={this.onBunkersChange}
                trackStyle={{ backgroundColor: '#ff6633', height: 4 }}
                handleStyle={{
                  borderColor: '#ff6633',
                  backgroundColor: '#ff6633',
                }}
              />
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-md-6'>
              <p className='refresh-sliders' onClick={this.resetSliders}>
                <i className='fa fa-refresh refresh-icon' aria-hidden='true' />{" "}
                reset sliders
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPlayForm() {
    return (
      <div className='row d-flex align-items-center flex-column h-100 mt-5'>
        <div className='col-md-12 mx-auto'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group'>
                <label htmlFor='program-activity-title'>Format</label>
                <select
                  onChange={this.handleInputChange}
                  value={this.state.format}
                  name='format'
                  className='form-control'
                >
                  {/* <option value={0} disabled>
                    Select Game Type
                  </option> */}
                  {this.renderPlayTypes()}
                </select>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='form-group'>
                <label htmlFor='program-activity-title'>Description</label>
                <textarea
                  type='text'
                  rows={3}
                  name='description'
                  className='form-control '
                  placeholder='Play Description'
                  value={this.state.description}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='program-activity-title'>Activity Date</label>
                <Datetime
                  name='firstStartDate'
                  value={moment(this.state.activityDate).format("MM-DD-YYYY")}
                  onChange={this.activityDateChanged}
                  dateFormat='MM-DD-YYYY'
                  timeFormat={false}
                  inputProps={{ readOnly: true }}
                  className='date-time'
                  {...this.state}
                />
              </div>
            </div>

            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='program-activity-title'>Location</label>
                <input
                  type='text'
                  name='location'
                  className='form-control'
                  id='play-activity-location'
                  placeholder='Location'
                  onChange={this.handleInputChange}
                  value={this.state.location}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='program-activity-title'>Yardage</label>
                <select
                  onChange={this.handleInputChange}
                  value={this.state.yardage}
                  name='yardage'
                  className='form-control'
                >
                  <option value={0}>
                    Select Level
                  </option>
                  {this.renderDivisions()}
                  <option value='fullTee' id='full-tee-box-option'>
                    Level 6 - 10 (Full Tee Box)
                  </option>
                </select>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='program-activity-title'>Full Tee Yardage</label>
                <input
                  type='number'
                  name='fullTeeYardage'
                  className='form-control'
                  id='play-activity-full-tee'
                  placeholder={
                    this.state.yardage === "fullTee"
                      ? "Enter Full Tee Yardage"
                      : "Choose Full Tee Option to Enter Custom Yardage"
                  }
                  disabled={this.state.yardage !== "fullTee"}
                  onChange={this.handleInputChange}
                  onWheel={(e) => e.target.blur()}
                  value={
                    this.state.yardage !== "fullTee"
                      ? ''
                      : this.state.fullTeeYardage
                  }
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='program-activity-title'>Score</label>
                <input
                  type='number'
                  name='score'
                  className='form-control'
                  id='play-activity-score'
                  placeholder='Score'
                  onChange={this.handleInputChange}
                  onWheel={(e) => e.target.blur()}
                  value={this.state.score}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='program-activity-title'>Holes</label>
                <select
                  onChange={this.handleHolesChange}
                  value={this.state.holes}
                  name='holes'
                  className='form-control'
                >
                  <option value={0} disabled>
                    Select Holes
                  </option>
                  <option value={9}>9 holes</option>
                  <option value={18}>18 holes</option>
                </select>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='program-activity-title'>Greens</label>
                <input
                  type='number'
                  name='greens'
                  className='form-control'
                  id='play-activity-greens'
                  placeholder='Enter Green Count'
                  onChange={this.handleInputChange}
                  onWheel={(e) => e.target.blur()}
                  value={this.state.greens}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='program-activity-title'>Putts</label>
                <input
                  type='number'
                  name='putts'
                  className='form-control'
                  id='play-activity-putts'
                  placeholder='Enter Putt Count'
                  onChange={this.handleInputChange}
                  onWheel={(e) => e.target.blur()}
                  value={this.state.putts}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 mx-auto d-flex justify-content-center mb-5 mt-3'>
              <div className='custom-control custom-switch'>
                <input
                  type='checkbox'
                  className='custom-control-input'
                  id='shareScoreSwitch'
                  checked={this.state.shareScore}
                  onChange={this.handleShareScoreChange}
                />
                <label
                  className='custom-control-label'
                  htmlFor='shareScoreSwitch'
                >
                  Share Score?
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderGoalSwitcher() {
    return (
      <div className='row mx-auto pb-5 mb-5 log-action-goal-card-container'>
        <div
          className={`card log-action-card col-md-4 log-action-goal-card ${
            this.state.actionSelected === 4 ? "log-action-card-active" : ""
          }`}
          onClick={() => this.handleActionSelect(4)}
        >
          {/* <img src={action.icon} alt={action.title} className="log-action-image"/> */}
          <div className='op36-curriculum-image-card-container'>
            <img
              src={Op36Icon}
              alt='Operation 36 Curriculum'
              className='op36-curriculum-image-card'
            />
          </div>
          <h5 className='log-action-title text-center'>Op 36 Curriculum</h5>
          <h5 className='log-action-subtitle'>Mark Student Goals Complete</h5>
        </div>
        <div
          className={`card log-action-card col-md-4 log-action-goal-card ${
            this.state.actionSelected === 5 ? "log-action-card-active" : ""
          }`}
          onClick={() => this.handleActionSelect(5)}
        >
          <i className='fa fa-plus log-action-goal-icon' />
          <h5 className='log-action-title text-center'>Add Private Goal</h5>
          <h5 className='log-action-subtitle'>Create Custom Goal</h5>
        </div>
      </div>
    );
  }

  renderGoalForm() {
    return (
      <div className='row d-flex align-items-center flex-column h-100 mt-5'>
        <div className='col-md-12 mx-auto'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group'>
                { this.props.objectives.length > 0 &&
                  !this.props.isRankCompleted &&
                  !this.props.loading ? (
                    <div>
                      <label htmlFor='objectives'>
                        Select Level {this.props.user.user_curriculums[0].rank_id}{' '}
                        Objective
                      </label>
                      <select
                        className='form-control'
                        id='objectives'
                        onChange={this.props.handleRankObjectiveSelectChanged}
                        value={this.props.issueTrophyFormObjectiveId}
                      >
                        <option value={-1} disabled>
                          Please Select Objective
                        </option>
                        {this.displayRankObjectives()}
                      </select>
                    </div>
                  ) : this.props.isRankCompleted && !this.props.loading ? (
                    <div className='col-md-6'>
                      <label htmlFor='levelUpButton'className='mx-auto' style={{ textAlign: 'center' }}>
                        Move To The Next Level
                      </label>
                      <button
                        id='levelUpButton'
                        type='button'
                        className='btn btn-block btn-primary'
                        onClick={this.props.progressRankForUser}
                      >
                        Level Up
                      </button>
                    </div>
                  ) : (
                    <div className='row'>
                      <div className='col-12'>
                        <div className='loading-content goal-loading '>
                          <Loader message='loading golfer objectives' />
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onObjectChange = (index, desc, progress, id) => {
    let goalTasks = [...this.state.goalTasks];

    goalTasks[index] = [progress, desc, id];
    this.setState(
      {
        goalTasks: goalTasks,
      },
      () => {
        let percentages = [0];
        let total = 0;

        this.state.goalTasks.map((task) => {
          percentages.push(task[0]);
        });

        total = percentages.reduce(function (acc, val) {
          return acc + val;
        });
        this.setState({
          goalCompletion: total,
        });
      }
    );
  };

  onSubmitTask = (data, index) => {
    const { closeLogActionsModal } = this.props;
    let goalTasks = [...this.state.goalTasks];
    if (this.state.pictures.length > 0) {
      this.setState({
        uploadingPhoto: true,
      });
    }
    this.setState(
      {
        goalTasks: goalTasks,
        goalLoader: true,
      },
      () => {
        if (this.state.submitClicked) {
          this.setState({
            actionSelected: -1,
          });
          this.props.goal_actions
            .createGoal(this.state, goalTasks)
            .then((res) => {
              this.props.user_actions
                .getUserGoals(this.props.user.id)
                .then(() => {
                  this.setState(
                    {
                      goalLoader: false,
                      uploadingPhoto: false,
                    },
                    () => {
                      closeLogActionsModal();
                      this.resetForms();
                    }
                  );
                });
            });
        }
      }
    );
  };

  renderPercentageText = () => {
    if (this.state.goalCompletion < 100) {
      return "set objective importance - each objective must add up to 100%";
    } else if (this.state.goalCompletion === 100) {
      return "You have successfully added objectives";
    } else if (this.state.goalCompletion > 100) {
      return "Delete objectives or decrease progress";
    }
  };

  /*********
  Upload Photo on Goal
  *********/

  handlePictureChange = (event) => {
    if (event.target.files.length > 0) {
      let selectedFiles = event.target.files;
      let { pictures } = this.state;

      for (let i = 0; i < selectedFiles.length; i++) {
        pictures.push(selectedFiles.item(i));
      }
      this.setState({
        pictures: pictures,
      });
    }
  };

  resetPictures = () => {
    this.setState({
      pictures: [],
    });
  };

  // onChangePhoto = event => {
  //   if (event.target.value) {
  //     this.setState({
  //       uploadPhotoName: event.target.value,
  //       uploadPhotoFile: event.target.files[0],
  //       didUploadPhoto: true,
  //     });
  //     var reader = new window.FileReader();
  //     var page = this
  //     reader.onload = function (e) {
  //       page.setState({
  //         uploadPhotoFileSrc: e.target.result
  //       });
  //     }
  //     reader.readAsDataURL(event.target.files[0]);
  //   }
  // }
  //
  //
  selectInputFile() {
    document.getElementById("input-photo-upload").click();
  }

  displayImageSelect() {
    if (this.state.uploadingPhoto) {
      return (
        <div id='GoalImage' className='text-center uploading-images-container'>
          <i
            className='fa fa-circle-o-notch fa-spin fa-lg fa-3x uploading-images-icon'
            aria-hidden='true'
          />
          <p className='mt-2 uploading-images-text'>uploading image(s)</p>
        </div>
      );
    } else {
      return (
        <ImageUploader
          label='Max Photos: 5 - Max file size: 5mb - supported file types: jpg | png'
          reset={this.resetPictures}
          id='input-photo-upload'
          onChange={this.handlePictureChange}
          accept={"image/*"}
          selectImages={this.selectInputFile}
          images={this.state.pictures}
          imageSources={this.state.uploadPhotoFileSources}
        />
      );
    }
  }

  renderPrivateGoalForm() {
    return (
      <div className='row d-flex align-items-center flex-column h-100 mt-5'>
        <div className='col-md-12 mx-auto'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='col-md-12'>
                <div className='form-group'>
                  <label htmlFor='program-activity-title'>Name</label>
                  <input
                    type='text'
                    name='goalName'
                    className='form-control'
                    id='goal-name'
                    placeholder='Enter a name for the goal'
                    onChange={this.handleInputChange}
                    value={this.state.goalName}
                  />
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group'>
                  <label htmlFor='program-activity-title'>
                    Video Url (optional)
                  </label>
                  <input
                    type='text'
                    name='goalVideo'
                    className='form-control'
                    id='goal-video'
                    placeholder='Enter a Youtube or Vimeo URL'
                    onChange={this.handleInputChange}
                    value={this.state.goalVideo}
                  />
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group'>
                  <label htmlFor='goalDescription'>Description</label>
                  <textarea
                    type='text'
                    rows={3}
                    name='goalDescription'
                    className='form-control '
                    placeholder='Insert why this goal is important to you...'
                    value={this.state.goalDescription}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='my-2 pl-2'>
                <label className='mt-3 my-0' htmlFor='goal-image'>
                  Goal Image Upload (optional)
                </label>
              </div>
              {/* <TextInput
                  name={''}
                  id={'input-photo-upload'}
                  type={'file'}
                  accept={"image/*"}
                  className={'form-control-file photoInput hide'}
                  onChange={this.onChangePhoto}
                  placeholder={'Add a Different Photo'}
                  value={this.state.uploadPhotoName}
                  error={''} /> */}
              {this.displayImageSelect()}
            </div>
            <div className='col-md-12 goal-objective-percentage-wrapper'>
              <label htmlFor='goalObjectives'>Goal Objectives</label>
              <div className='goal-percentage-wrapper hidden-sm-down'>
                <p className='goal-percentage-desc'>
                  {this.renderPercentageText()}
                </p>
                <div
                  className='goal-percentage-tracker'
                  style={{
                    backgroundColor:
                      this.state.goalCompletion === 100 ? "#33d9b2" : "",
                  }}
                >
                  {`Goal Completion: ${this.state.goalCompletion}%`}
                </div>
              </div>
            </div>
            {this.renderGoalTasks()}
            <div className='col-md-12 goal-objective-border' />
            <div className='col-md-12 goal-objective-icon-wrapper'>
              {this.state.goalTaskArray.length > 1 ? (
                <i
                  className='fa fa-minus goal-objective-minus-icon'
                  onClick={this.deleteGoalTask}
                />
              ) : null}
              <i
                className='fa fa-plus goal-objective-add-icon'
                onClick={this.createGoalTask}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderActiveForm = () => {
    const { showCreateGoal } = this.props;
    if (showCreateGoal) {
      return this.renderPrivateGoalForm();
    } else {
      switch (this.state.actionSelected) {
        case 1:
          return this.renderPlayForm();
          break;
        case 2:
          return this.renderTrainForm();
          break;
        case 3:
          return this.renderPrivateGoalForm();
          break;
        case 4:
          if (!this.props.gotObjectives) {
            this.props.getUserRanks(this.props.user);
          }
          return this.renderGoalForm();
          break;
        default:
          return null;
          break;
      }
    }
  };

  renderActiveButton() {
    const { showCreateGoal } = this.props;
    if (showCreateGoal) {
      if (
        this.state.goalName !== "" &&
        this.state.goalDescription !== "" &&
        this.state.goalCompletion === 100 &&
        !this.state.uploadingPhoto &&
        this.state.pictures.length <= 5
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      switch (this.state.actionSelected) {
        case 1:
          return false;
          break;
        case 2:
          return false;
          break;
        case 3:
          if (
            this.state.goalName !== "" &&
            this.state.goalDescription !== "" &&
            this.state.goalCompletion === 100 &&
            !this.state.uploadingPhoto &&
            this.state.pictures.length <= 5
          ) {
            return false;
          } else {
            return true;
          }
          break;
        case 4:
          return false;
          break;
        default:
          return true;
          break;
      }
    }
  }

  renderUpdateText() {
    const { showCreateGoal } = this.props;
    if (showCreateGoal) {
      return "Add Private Goal";
    } else {
      switch (this.state.actionSelected) {
        case 1:
          return "Log Round";
          break;
        case 2:
          return "Log Practice Session";
          break;
        case 3:
          return "Add Private Goal";
          break;
        case 4:
          return "Log Goal";
          break;
        default:
          return "Applying Changes";
          break;
      }
    }
  }

  renderModalTitle() {
    const { showCreateGoal } = this.props;
    if (showCreateGoal) {
      return "New Private Goal";
    } else {
      switch (this.state.actionSelected) {
        case 1:
          return "Log Round";
          break;
        case 2:
          return "Log Practice";
          break;
        case 3:
          return "New Private Goal";
          break;
        case 4:
          return "Log Goal";
          break;
        case 5:
          return "New Private Goal";
          break;
        default:
          return "Log Actions";
          break;
      }
    }
  }

  // Render Main Content

  modalBack = (previous) => {
    this.setState(
      {
        actionSelected: -1,
      },
      () => {
        previous();
      }
    );
  };

  render() {
    const { userLoader, showCreateGoal } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.showLogActionsModal}
          contentLabel='Log Actions'
        >
          <div style={{ overflowX: "hidden" }}>
            <div className='modal-header row'>
              <div className='col-10'>
                {userLoader ? (
                  <h5 className='modal-title display-4 uppercase detail-title'>
                    Loading Golfer
                  </h5>
                ) : (
                  <h5 className='modal-title display-4 uppercase detail-title'>{`${this.renderModalTitle()} for ${
                    this.props.user ? this.props.user.first_name : ""
                  } ${this.props.user ? this.props.user.last_name : ""}`}</h5>
                )}
              </div>
              <div className='col-2'>
                <button
                  type='button'
                  className='close pull-right font-200 modal-close-btn'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={this.props.closeLogActionsModal}
                >
                  <span aria-hidden='true'>✕</span>
                </button>
              </div>
            </div>
            <div className='modal-content container p-3'>
              {userLoader ? (
                <Loader message='loading golfer' />
              ) : (
                <Wizard>
                  <Steps>
                    <Step
                      id='step1'
                      render={({ previous, next }) => (
                        <div>
                          <div className='row mx-auto pb-5 mb-5'>
                            {this.logActionsWelcome(next)}
                          </div>
                          <div className='row bg-white pb-3'>
                            <div className='col-6 mx-auto'>
                              <div className='row'>
                                <div className='col-md-12'>
                                  <button
                                    className='btn btn-block btn-primary'
                                    onClick={next}
                                    disabled={
                                      this.state.actionSelected > 0
                                        ? false
                                        : true
                                    }
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                    <Step
                      id='step2'
                      render={({ previous, next }) => (
                        <div className='pb-5 mb-5'>
                          {this.renderActiveForm()}
                          <div className='row bg-white pb-3'>
                            <div className='col-6 mx-auto'>
                              <div className='row'>
                                {!showCreateGoal && (
                                  <div className='col-md-6'>
                                    <button
                                      className='btn w-100 btn-dark log-actions-button'
                                      onClick={() => this.modalBack(previous)}
                                    >
                                      Previous
                                    </button>
                                  </div>
                                )}

                                <div
                                  className={`col-md-6 ${
                                    showCreateGoal ? "mx-auto" : ""
                                  }`}
                                >
                                  <button
                                    className='btn btn-block btn-primary text-center log-actions-button'
                                    onClick={() => this.handleLogClick()}
                                    disabled={this.renderActiveButton()}
                                  >
                                    {this.renderUpdateText()}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </Steps>
                </Wizard>
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

LogActionsModal.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    user_curriculums: PropTypes.arrayOf({
      rank_id: PropTypes.number,
    }),
  }),
  actions: PropTypes.shape({
    getUserActivities: PropTypes.func,
  }),
  objectives: PropTypes.shape({
    length: PropTypes.number,
  }),
  subjects: PropTypes.shape({
    map: PropTypes.func,
  }),
  current_user: PropTypes.shape({
    type: PropTypes.string,
  }),
  playTypes: PropTypes.array,
  isRankCompleted: PropTypes.func,
  loading: PropTypes.bool,
  handleRankObjectiveSelectChanged: PropTypes.func,
  issueTrophyFormObjectiveId: PropTypes.number,
  progressRankForUser: PropTypes.func,
}

LogActionsModal.propTypes = {
  toggleValidationModal: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch),
    activity_actions: bindActionCreators(activityActions, dispatch),
    objective_actions: bindActionCreators(objectiveActions, dispatch),
    goal_actions: bindActionCreators(goalActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
  };
}

LogActionsModal.propTypes = {
  setActivityAdded: PropTypes.func,

}

export default connect(null, mapDispatchToProps)(LogActionsModal);
