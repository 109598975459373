import styles from './CoachDashboardProgramsOverview.module.scss'
import { ReactComponent as GolferIcon } from '../../../assets/images/common/icons/golfer-half-icon.svg'
import { ReactComponent as EnrollmentsIcon } from '../../../assets/images/common/icons/sidebarMenu/Account.svg'
import { ReactComponent as ProgramsIcon } from '../../../assets/images/common/icons/sidebarMenu/Programs.svg'
import { useDispatch, useSelector } from 'react-redux'
import ProgramsOverviewAction from './ProgramsOverviewAction'
import { useEffect } from 'react'
import { getPrograms } from '../../../actions/programActions'
import PropTypes from 'prop-types'

const CoachDashboardProgramsOverview = ({ communityId }) => {
  const activeCourses = useSelector(state => state.dashboard.activeCourses)
  const futureCourses = useSelector(state => state.dashboard.futureCourses)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPrograms())
    dispatch(getPrograms({ future: true }))
  }, [communityId, dispatch])

  const activeEnrollmentsCount = activeCourses?.reduce((count, course) => count + course.students.length, 0)

  const spotsAvailable = activeCourses?.map(course => course.golferLimit ? [course.students.length, course.golferLimit] : [0, 0])
    .reduce((count, courseSpots) => [count[0] + courseSpots[0], count[1] + courseSpots[1]], [0, 0])

  return (
    <div className={styles.CoachDashboardProgramsOverview}>
      <div className={styles.title}>Current Programs Overview</div>
      <div className={styles.programsOverviewContainer}>
        <div className={styles.programsOverviewItem}>
          <div className={styles.iconWrapper}>
            <EnrollmentsIcon className={styles.icon}/>
          </div>
          <div className={styles.info}>
            <div className={styles.data}>{activeEnrollmentsCount}</div>
            <div className={styles.text}>Active Enrollment(s)</div>
          </div>
        </div>

        <div className={styles.programsOverviewItem}>
          <div className={styles.iconWrapper}>
            <GolferIcon className={styles.icon}/>
          </div>
          {spotsAvailable &&
            <div className={styles.info}>
              <div className={styles.textSpots}>
                <span className={styles.spotsTaken}>{spotsAvailable[1] - spotsAvailable[0]}</span>
                /
                <span>{spotsAvailable[1]} </span>
                Spots Available
              </div>
            </div>
          }
        </div>

        <div className={styles.programsOverviewItem}>
          <div className={styles.iconWrapper}>
            <ProgramsIcon className={styles.icon}/>
          </div>
          <div className={styles.info}>
            <div className={styles.data}>{activeCourses?.length}</div>
            <div className={styles.text}>Active Program(s)</div>
          </div>
        </div>

        <div className={styles.programsOverviewItem}>
          <div className={styles.iconWrapper}>
            <ProgramsIcon className={styles.icon}/>
          </div>
          <div className={styles.info}>
            <div className={styles.data}>{futureCourses.length}</div>
            <div className={styles.text}>Future Program(s)</div>
          </div>
        </div>
      </div>

      <ProgramsOverviewAction
        programs={activeCourses?.length}
        spots={spotsAvailable && spotsAvailable[1] - spotsAvailable[0]}
        enrollments={activeEnrollmentsCount}
      />
    </div>
  )
}

CoachDashboardProgramsOverview.propTypes = {
  communityId: PropTypes.number,
}

export default CoachDashboardProgramsOverview
